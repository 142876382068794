<!-- <template>
  <div>
    <v-data-table
      :headers="headers"
      :items="apiList"
      :items-per-page="5"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>API List</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td><v-checkbox v-model="item.checkboxValue" v-if="item.arguments.some(arg => arg.type === 'boolean')"></v-checkbox></td>
          <td>
            <v-text-field
              v-model="item.argumentsInput"
              placeholder="Input arguments"
             
            ></v-text-field>
    
          </td>
          <td><v-btn @click="callFunction(item)">Call</v-btn></td>
        </tr>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <v-text-field v-model="outputText" label="Output" readonly></v-text-field>
  </div>
</template> -->


<template>
  <div>
    <div v-for="(apiList, category) in categorizedApiLists" :key="category">
      <v-data-table v-if="apiList.length > 0" :headers="headers" :items="apiList" hide-default-footer>
        <!-- ... (same template as before) ... -->
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>API</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-toolbar-title>{{ category.toUpperCase() }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-toolbar-title>WEB3</v-toolbar-title> -->
            <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field> -->
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr @click="closeResponse(item)">
            <td style="width: 700px;">{{ getFuncName(item) }}<b>{{ formatInputArguments(item) }}</b></td>
            <td>
              <span v-html="formatDescription(item.description)"></span>
            </td>

            <!-- Add a new column for input arguments -->
            <!-- <td>
              {{ formatInputArguments(item) }}
            </td> -->
            <!-- <td><v-checkbox :disabled="!item.arguments.some(arg => arg.type === 'boolean')" v-model="item.checkboxValue" @change="changedCallback(item)" ></v-checkbox></td>
            <td>
              <v-select :disabled="!item.arguments.some(arg => arg.name === 'decimals')" v-model="item.decimalSelector" :items="[0,1,2,3,4,5,6,7,8,9,10]" label="Decimals" @change="changedCallback(item)" dense></v-select>
            </td>
            <td>
              <v-text-field :disabled="(item.arguments.length === 0 || item.arguments.some(arg => arg.name === 'in_wei') || item.arguments.some(arg => arg.name === 'decimals'))" v-model="item.argumentsInput" placeholder="Input arguments"></v-text-field>
            </td> -->
            <!-- <td>
              <v-btn v-if="!item.showResponse" @click="callFunction(item)">Call</v-btn>
              <v-btn v-else @click.stop="closeResponse(item)">Close</v-btn>
            </td> -->

          </tr>
          <tr v-if="item.showResponse">
            <td :colspan="headers.length+2"> <!-- Increase colspan to accommodate the new column -->
              <v-text-field v-model="item.outputText" label="Output" readonly></v-text-field>
            </td>
          </tr>
        </template>


      </v-data-table>

      <v-divider v-if="category !== 'nodes'"></v-divider>
    </div>



    <v-card class="sandbox-card">
      <v-card-title class="sandbox-title">Sandbox</v-card-title>
      <v-row justify="center">
        <v-col>
          <v-card class="api-card">
            <v-card-title class="api-title">API Showcase</v-card-title>
            <v-row align="center">
              <v-col>
                <v-select v-model="selectedEvent" :items="eventOptions" label="Select Event" @change="handleSelectClick"></v-select>
              </v-col>
              <v-col>
                <v-text-field v-model="customTextInputs" label="Custom Arguments"></v-text-field>
              </v-col>
              <v-col>
                <v-btn class="mt-4" @click="callCustomFunction" color="primary">Call</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-4">
        <v-col>
          <v-card class="api-card">
            <v-card-title class="api-title">API Call</v-card-title>
            <v-card-text>
              <v-textarea
                ref="textAreaContent"
                :value="customTextInputs ? `${selectedEvent}(${customTextInputs})` : selectedEvent"
                readonly
                outlined
                dense
                hide-details
                auto-grow
                rows="5"
                style="font-family: monospace; white-space: pre-wrap;"
              ></v-textarea>
              <v-btn class="mt-4" @click="copyToClipboard" color="primary">Copy to Clipboard</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-4">
        <v-col>
          <v-card class="api-card">
            <v-card-title class="api-title">API Result</v-card-title>
            <v-card-text>
              <v-textarea
                v-model="liveHookResult"
                readonly
                outlined
                dense
                hide-details
                auto-grow
                style="font-family: monospace; white-space: pre-wrap;"
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>





  </div>
</template>

<script>
export default {
  data() {
    return {
        eventOptions: [],
        selectedEvent: '',
        customTextInputs: '',
        liveHookResult: '',
        headers: [
          { text: 'Function', value: 'name' },
          { text: 'Description', value: 'description' },
          // { text: '', value: 'args' },
          // { text: 'Checkbox', value: 'checkbox' },
          // { text: 'Input Arguments', value: 'argumentsInput' },
          // { text: 'Call', value: 'callButton' },
        ],
        apiList : [
          {
              'name': 'window.cubitsfs.api.data.multisig.multisig_required',
              'arguments': [],
              'return_arguments': [{'name': 'count', 'type': 'integer'}],
              'description':'returns how many multisig members are required to make consensus decisions'
          },
          {
              'name': 'window.cubitsfs.api.data.multisig.multisig_members',
              'arguments': [],
              'return_arguments': [{'name': 'members', 'type': 'array'}],
              'description':'returns a list of eligable multisig members'
          },
          {
              'name': 'window.cubitsfs.api.data.multisig.is_multisig_member',
              'arguments': [{'name': 'wallet_id', 'type': 'string'}],
              'return_arguments': [{'name': 'validity', 'type': 'boolean'}],
              'description':'returns true/false whether a wallet is a multisig member'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.circulating_supply_cubits',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the circulating supply of cubits ( removed: burned amount + node cubits )'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.real_supply_cubits',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the real supply of cubits ( removed: burned amount )'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.total_supply_cubits',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total supply of cubits'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.totalcubits',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total amount of cubits that have been created'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.totalUSDT',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total amount of USDT in circulation'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.usdt_pool',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total amount of USDT in USDT bridge'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.createdCubits',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total amount of cubits that have been bought'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.deletedCubits',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total amount of cubits that have been sold'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.nodeCubits',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total amount of cubits that have been generated by nodes'
          },
          {
              'name': 'window.cubitsfs.api.data.supply.cubitsUSDTpool',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total amount of USDT of collateral in cubits pool'
          },
          {
              'name': 'window.cubitsfs.api.data.storage.contractsTotalSize',
              'arguments': [{'name': 'readable', 'type': 'boolean', 'description': 'human readable format'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total storage size of active contracts'
          },
          {
              'name': 'window.cubitsfs.api.data.storage.getAvailableStorageSize',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total available storage size'
          },
          {
              'name': 'window.cubitsfs.api.data.storage.getUsableStorageSize',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the total usable storage size'
          },
          {
              'name': 'window.cubitsfs.api.data.storage.contractsTotalCount',
              'arguments': [],
              'return_arguments': [{'name': 'count', 'type': 'integer'}],
              'description':'returns the total count of active contracts'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.GetPayOutShare',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the current payout share for nodes'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.MAXIMUM_STORAGE_TIME',
              'arguments': [],
              'return_arguments': [{'name': 'time_days', 'type': 'integer'}],
              'description':'returns the current maximum storage size'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.PAYOUT_LAST_REWARD',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the last reward amount'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.PAYOUT_THRESHOLD',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the threshold before payments are sent out into the network'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.active_nodeCount',
              'arguments': [],
              'return_arguments': [{'name': 'count', 'type': 'integer'}],
              'description':'returns the active node count'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.get_pending_payout',
              'arguments': [{'name': 'wallet_address', 'type': 'unknown'}, {'name': 'in_wei', 'type': 'boolean'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the amount of payout pending for specific node'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.getCubitShare',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the current cubit share'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.getCurrentCollateralRequirement',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the current collateral requirement to run a node'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.getDailyRewardShare',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the independent daily reward share for nodes based on network efficiency'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.getHostingCostNodePerDay',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns a reference cost-value of hosting cost per day for nodes'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.next_payout',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}],
              'return_arguments': [{'name': 'hours_away', 'type': 'float'}],
              'description':'returns the remaining time until payout to nodes is unlocked'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.get_node_count',
              'arguments': [],
              'return_arguments': [{'name': 'count', 'type': 'integer'}],
              'description':'returns the current count of all nodes'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.get_payout_accumulation',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the amount of cubits locked for payout to nodes who are below payment threshold'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.get_node_collateral',
              'arguments': [{'name': 'in_wei', 'type': 'boolean'}, {'name': 'decimals', 'type': 'integer'}],
              'return_arguments': [{'name': 'amount', 'type': 'float'}],
              'description':'returns the current node collateral'
          },
          {
              'name': 'window.cubitsfs.api.data.nodes.node_statistics',
              'arguments': [{'name': 'wallet_address', 'type': 'unknown'}],
              'return_arguments': [{'name': 'stats', 'type': 'array'}],
              'description':'returns wallet based statistics for any node in the network'
          }
      ],
      search: '',
      outputText: '',
    };
  },
  methods: {
    handleSelectClick() {
      this.codeSnippet = this.selectedEvent; 
      // const selectedFunction = this.apiList.find(api => api.name === this.selectedEvent);
      this.customTextInputs = this.formatInputArguments(this.apiIndex[this.selectedEvent]).replace("(","").replace(")","");
    },
    callCustomFunction() {
      // Find the selected function in the apiList
      const selectedFunction = this.apiIndex[this.selectedEvent];  //this.apiList.find(api => api.name === this.selectedEvent);
      
      if (!selectedFunction) {
        this.liveHookResult = 'Error: Selected function not found';
        return;
      }


      // Call the selected function dynamically
      this.liveHookResult = 'Calling API...'; // Display a loading message
      
      window.cubitsfs.api.data[selectedFunction.name.split('.')[4]][selectedFunction.name.split('.')[5]](...this.customTextInputs.split(',').map(arg => arg.trim()))
        .then(response => {
          this.liveHookResult = this.serializeObjectToString(response); 
        })
        .catch(error => {
          this.liveHookResult = 'Error: ' + error.message;
        });
    },
    serializeObjectToString(obj) {
        let result = '';
        let hasNonNumericKey = false;

        if (typeof obj === 'string') {
            return `${obj}\n`;
        }

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                if (!(/^\d+$/.test(key)) && typeof value !== 'object') {
                    result += `${key}:${value}\n`;
                    hasNonNumericKey = true;
                } else if (typeof value === 'object') {
                    result += `${key}:${JSON.stringify(value)}\n`;
                    hasNonNumericKey = true;
                }
            }
        }

        return hasNonNumericKey ? result.trim() : obj;
    },
    formatDescription(description) {
      return description.replace(/returns/g, '<b>returns</b>');
      // return description.toUpperCase().replace(/RETURNS/g, '<b>returns</b>');
    },
    formatInputArguments(api) {
      const args = api.arguments.map(arg => {
        if (arg.type === 'boolean') {
          return api.checkboxValue || true;
        } else if (arg.name === 'decimals') {
          return api.decimalSelector != -1 ? api.decimalSelector : 10; // Replace with actual value for integers
        } else if (arg.name === 'wallet_address' || arg.name === 'wallet_id') {
          return "0x0000000000000000000000000000000000000000"; // Replace with actual value for integers
        } else {
          return api.argumentsInput;
        }
      });

      return `(${args.join(', ')})`;
    },
    async callFunction(api) {
      try {
        if (window.cubitsfs && window.cubitsfs.api && window.cubitsfs.api.data && window.cubitsfs.api.data.nodes) {
          const args = api.arguments.map(arg => {
            if (arg.type === 'boolean') {
              return api.checkboxValue || false;
            } else if (arg.name === 'decimals') {
              return api.decimalSelector!= -1 ? api.decimalSelector : 15; // Replace with actual value for integers
            } else {
              // Handle other argument types if needed
              
              return api.argumentsInput;
            }
          });

          // this.outputText = await window.cubitsfs.api.data[api.name.split(".")[4]][api.name.split(".")[5]](...args);
          api.outputText = await window.cubitsfs.api.data[api.name.split(".")[4]][api.name.split(".")[5]](...args);
          // api.outputText += "\t"+await window.cubitsfs.api.data[api.name.split(".")[4]][api.name.split(".")[5]](true);
          // api.outputText += "\t"+await window.cubitsfs.api.data[api.name.split(".")[4]][api.name.split(".")[5]](true,5);
          // api.outputText = Number(api.outputText).toLocaleString().replace(/,/g, '')
          api.showResponse = true; // Show the response
        } else {
          console.error("Error: window.cubitsfs or its properties are undefined.");
        }
      } catch (error) {
        console.error("Error executing function:", error);
      }
    },
    closeResponse(api) {
      api.showResponse = false; // Hide the response
    },
    changedCallback(api) {
      this.callFunction(api)
    },
    getFuncName(api) {
        let _name = api.name
        // if (api.checkboxValue) {
        //   _name +="("+api.checkboxValue
        // }
        // if (api.decimalSelector != -1) {
        //   _name +=","+api.decimalSelector
        //   _name +=")"
        // }
       return _name //!api.arguments.some(arg => arg.type === 'boolean')

    },
    copyToClipboard() {
      // Get the content of the textarea
      const textAreaContent = this.$refs.textAreaContent.value;

      // Copy content to clipboard
      navigator.clipboard.writeText(textAreaContent)
        .then(() => {
          // Success message
          this.$toast.success('Code snippet copied to clipboard');
        })
        .catch((error) => {
          // Error message
          console.error('Error copying to clipboard:', error);
          this.$toast.error('Error copying code snippet to clipboard');
        });
    }

    
  },
  computed: {
    categorizedApiLists() {
      return {
        'storage': this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.storage')),
        'multisig': this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.multisig')),
        'supply': this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.supply')),
        'nodes': this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.nodes')),
      };
    },
    storageApiList() {
      return this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.storage'));
    },
    multisigApiList() {
      return this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.multisig'));
    },
    supplyApiList() {
      return this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.supply'));
    },
    nodesApiList() {
      return this.apiList.filter(api => api.name.includes('window.cubitsfs.api.data.nodes'));
    },
  },
  mounted() {
    this.apiList = this.apiList.map(api => ({ ...api, outputText: '', showResponse: false,decimalSelector:-1 }));
    this.eventOptions = this.apiList.map(api => api.name);
    this.apiIndex = {};
      
    // Iterate over the apiList array
    this.apiList.forEach(api => {
      // Set the API object in the apiIndex with its name as the key
      this.$set(this.apiIndex, api.name, api);
    });
  },
};
</script>

<style>
/* CSS for API Call */
.sandbox-card {
  width: 100%;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.sandbox-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.api-card {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.api-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>