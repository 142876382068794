<!-- CopyField.vue -->

<!-- :append-icon="localCopySuccess ? 'mdi-check' : ''" -->
<!-- :outlined="!localCopySuccess" -->
<!-- :append-icon="localCopySuccess ? 'mdi-check' : ''" -->
<!-- :prepend-icon="icon" -->
<template>
    <div>
      <v-textarea
        readonly
        :id="fieldId"
        no-resize
        :label="label"
        :prepend-icon="icon"
        :append-icon="!walletvalid ? null : 'mdi-content-copy'"
        variant="outlined"
        :rows="rowsize"
        dense
        :counter="false"
        filled
        @click:append="copyText" 
        :value="text"
      ></v-textarea>
  
      <!-- <v-btn
        size="x-large"
        :disabled="!walletvalid"
        @click="copyText"
        v-if="!localCopySuccess"
        style="position: absolute; top: 52px; right: 20px;"
      >
        Copy
      </v-btn> -->
  
      <v-chip class="ma-2 pa-2" color="success" v-if="localCopySuccess" style="position: absolute; bottom: -4px; right: 4px;">
        Successfully copied.
      </v-chip>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        localCopySuccess: false,
        // ... other data properties
      };
    },
    props: {
      label: {
        type: String,
        default: "Default Label"
      },
      description: {
        type: String,
        default: "Default Description"
      },
      text: {
        type: String,
        default: ""
      },
      walletvalid: {
        type: Boolean,
        default: false
      },
      copiedcodeworked: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: "mdi-server"
      },
      rowsize: {
        type: Number,
        default: 4
      },
    },
    computed: {
        fieldId() {
        return `copytextfield_${Math.random().toString(36).substr(2, 9)}`;
        }
    },
    watch: {
      copiedcodeworked(newValue) {
        // Update the local state when the prop changes
        this.localCopySuccess = newValue;
      },
    },
    methods: {
      handleAppendClick() {
        // Handle the click on the append icon
        console.log('Append icon clicked');
        this.copyText()
      },
      copyText() {
        const element = document.getElementById(this.fieldId);
        element.select();
        element.setSelectionRange(0, 99999);
        document.execCommand('copy');
        this.$emit("copy", this.text); // Emit the "copy" event
        this.localCopySuccess = true;
        setTimeout(() => {
          this.localCopySuccess = false;
        }, 900);
      },
      copyToClipboard() {

        this.localCopySuccess = true;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>
  