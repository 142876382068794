<template>


   


    <v-card
      width="100%"

      class="px-4 py-4"
    >

          <!-- DOWNLOADER DIALOGUE/LOADER -->
          <v-dialog
            v-model="dialog_getting_file"
            :scrim="false"
            persistent
            width="auto"
          >
            <v-card
              color="primary"
            >
              <v-card-text>
                <!-- Downloading Your File -->
                Decrypting Your File
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>


      <v-tabs
        center-active
        
        class="pb-3 navbar-light theme-nav"
        >

        <v-tab @click="login"><img :src='"../assets/images/storageprotocol/metamask.png"' height="48" alt="logo">Metamask</v-tab>
        <v-tab ></v-tab>
        <v-tab ></v-tab>
        
        <!--<v-tab @click="showSignupSection">Signup</v-tab>-->
      </v-tabs>




      <v-card v-if="userSection">


 


        <v-row justify="center" align="center"> 
          <div class="container px-2 pt-3 flex flex-wrap flex-col md:flex-column"> 
            <v-col class="py-1">
              <v-btn block color="#2479d8" dark small @click="showUploadSection">
                Upload File
                <v-icon light class="ml-2">mdi-upload</v-icon>
              </v-btn>
            </v-col>
            <v-col class="py-1">
              <v-btn block color="#2479d8" dark small @click="showUploadDirectorySection">
                Upload Directory
                <v-icon light class="ml-2">mdi-upload</v-icon>
              </v-btn>
            </v-col>
            <v-col class="py-1">
              <v-btn block color="#2479d8" dark small @click="showViewDataSection">
                View Data
                <v-icon light class="ml-2">mdi-folder</v-icon>
              </v-btn>
            </v-col>
          </div>
          <!-- <v-col cols="1" class="pl-0" justify="center" align="center">
            <v-btn color="red" dark small @click="removeAll">
              X
              <v-icon light>mdi-logout</v-icon>
            </v-btn>
          </v-col> -->

          <!-- <v-col cols="4" class="pl-2" justify="center" align="center">
            <v-btn color="black" dark small @click="logout">
              Switch Account
              <v-icon light>mdi-logout</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
      </v-card>


      <v-card class="mt-6" v-if="userSection">
        <!-- <v-row justify="center" align="center"> -->
             <!-- <v-system-bar class="bg-color grey"> -->
             <!-- <v-system-bar class="label-background" :height="24"> -->
             <v-system-bar class="label-background px-1" style="overflow:hidden">
                  <!-- <v-icon icon="mdi-wifi-strength-4"></v-icon>
                  <v-icon icon="mdi-signal" class="ms-2"></v-icon>
                  <v-icon icon="mdi-battery" class="ms-2"></v-icon> -->

                  <v-row class="prof-img-row font-weight-normal" no-gutters justify="center" align="center" :style="{ backgroundSize:'24px',backgroundPositionX:'1px', backgroundImage: `url(${cubitsfs.utility.getBackgroundImage(cubitsfs.accounts[0])})` }">

                                
                  <strong class="pl-8">
                    <a style="font-size:14px;color:white;" :href="'//'+cubitsfs.explorer_address+'/address/'+ cubitsfs.accounts[0]" target="_blank">
                      <!-- <img :src="cubitsfs.utility.getBackgroundImage(cubitsfs.accounts[0])" height="24" alt="userIDlogo"> -->
                      <!-- <span style="background-color:white;color:black;">  -->
                      <span style=""> 
                        {{ cubitsfs.accounts[0] }} 
                      </span>
                      
                      <span style="color:white;" class="ml-3"> 
                        <!-- {{ formatCubits(getAccountValue()) }} <img :src='"../assets/images/storageprotocol/cubits.svg"' height="18" alt="logo" style="margin-top:-3px;margin-right: 5px;filter: invert(0.53) sepia(1) saturate(5) hue-rotate(175deg)"> -->
                        {{ displayCubits(getAccountValue()) }} <img :src='"../assets/images/storageprotocol/cubits.svg"' height="18" alt="logo" style="margin-top:-3px;margin-right: 5px;">
                      </span>
                    </a> 
                  </strong>

                  </v-row>

            </v-system-bar>



              
            <div class="container pt-0 pb-3">




      

              <div class="row margin-bottom">

                <div class="col-10 ml-2 mt-3 p-0">
         
                </div>

              </div>
            </div>

        

        <!-- </v-row> -->
      </v-card>

      <v-card v-if="uploadSection" class="mt-2 pb-2">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="8">
            <v-text-field
              label="Upload Contract Name"
              :readonly="inputs_readonly"
              @change="setContractName"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row justify="center" align="center" class="mt-0">
            <v-col cols="8">

           
              <v-menu
                v-model="menu2" 
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_create"
                    label="Maximum Storage Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_create"
                  :min="getminDate()"
                  :max="getmaxDate()"
                  :readonly="inputs_readonly"
                  @input="menu2 = false"
                  @change="setContractDuration($event)"
                ></v-date-picker>
              </v-menu>
          
            </v-col>

        </v-row>

        <v-row no-gutters justify="center" align="center" class="mt-0">
            <v-col cols="5">

                 <!-- <input id="replications_upload" type="number" min="3" max="50" value="3" @change="setContractReplication($event)"> -->
                  <v-subheader class="pl-6">
                    Replication Factor
                  </v-subheader>
                  <v-slider
                    class="mt-4"
                    id="replications_upload" 
                    max="20"
                    min="5"
                    v-model="replication_slider"
                    :thumb-size="24"
                    :readonly="inputs_readonly"
                    thumb-label="always"
                    
                    @change="setReplication($event)"
                  ></v-slider>

            </v-col>
            <v-col cols="3">
              <v-subheader class="pl-6" style="margin-top:-5px;" v-if="supports_crypto">
                Encrypt File?
              </v-subheader>
              <v-checkbox v-if="supports_crypto"
                class="pl-12"
                id="encryption_box"
                :disabled="inputs_readonly"
                :input-value="useEncryption"
                value
              
                @change="activateEncryption($event)"
              ></v-checkbox>
              <v-subheader no-gutters justify="center" align="center"  class="pl-6" style="margin-top:-5px;" v-if="!supports_crypto">
                Device does not support Encryption
              </v-subheader>


            </v-col>
        </v-row>

        <v-row no-gutters justify="center" align="center" class="mt-0">
          <v-col cols="8">
            <v-file-input
              ref="inputFile"
              show-size
              label="File input"
              :disabled="inputs_readonly"
              @change="selectFile"
            ></v-file-input>
          </v-col>
        </v-row>

        <v-card v-if="fileUploadCost>0 && (currentFile || currentFile_encr)" color="#840032" class="pb-0 pt-0 my-0 mx-6" height="26">
          <v-row no-gutters justify="center" align="center" class="mt-0">
   
              <p  class="white--text text-center pt-0"> {{ fileUploadCost }} <img :src='"../assets/images/storageprotocol/cubits.svg"' height="18" alt="logo" > </p>
           
          </v-row>
        </v-card>

        <v-row no-gutters justify="center" align="center" class="mt-3 mb-1">
          <v-btn color="#2479d8" dark small @click="upload" :disabled="inputs_readonly" >
            Upload
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </v-row>


        <!-- <v-row justify="center" align="center">
          <v-col cols="4" class="pl-2 pb-4" justify="center" align="center">
            <v-btn color="#2479d8" dark small @click="upload" :disabled="inputs_readonly">
              Upload
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row> -->

      </v-card>




      <v-card v-if="uploadDirectorySection" class="mt-2 pb-2">
        <v-row no-gutters justify="center" align="center">
          <v-col cols="8">
            <v-text-field
              label="Upload Contract Name"
              :readonly="inputs_readonly"
              @change="setContractName"
            ></v-text-field>
          </v-col>

        </v-row>

        <v-row justify="center" align="center" class="mt-0">
            <!-- <v-col cols="8">
                 <input type="date" :min="getminDate()" :max="getmaxDate()" name="dateofcontract" @change="setContractDuration($event)">
            </v-col> -->

            <v-col cols="8">
              <v-menu
                v-model="menu2" 
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_create"
                    label="Maximum Storage Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_create"
                  :min="getminDate()"
                  :max="getmaxDate()"
                  :readonly="inputs_readonly"
                  @input="menu2 = false"
                  @change="setContractDuration($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>


        </v-row>





        
        <v-row no-gutters justify="center" align="center" class="mt-0">
          <v-col cols="5">

            <!-- <input id="replications_upload" type="number" min="3" max="50" value="3" @change="setContractReplication($event)"> -->
            <v-subheader class="pl-6">
              Replication Factor
            </v-subheader>
            <v-slider
              class="mt-4"
              id="replications_upload" 
              max="20"
              min="5"
              v-model="replication_slider"
              :thumb-size="24"
              :readonly="inputs_readonly"
              thumb-label="always"
              
              @change="setReplication($event)"
            ></v-slider>

            </v-col>
            <v-col cols="3">
            <v-subheader class="pl-6" style="margin-top:-5px;">
              Encrypt File?
            </v-subheader>
            <v-checkbox 
              class="pl-12"
              id="encryption_box"
              :input-value="useEncryption"
              :disabled="inputs_readonly"
              value

              @change="activateEncryption($event)"
            ></v-checkbox>
            </v-col>
        </v-row>

        <!-- <v-row no-gutters justify="center" align="center">
          <v-col cols="8">
            <input id="replications_upload" type="number" min="3" max="50" value="3" @change="setContractReplication($event)">
          </v-col>

        </v-row> -->


        <v-row no-gutters justify="center" align="center" class="mt-0">
          <v-col cols="8">
            <v-file-input
              show-size
              multiple
              webkitdirectory
              COUNTER
              prepend-icon="mdi-folder"
              label="Directory input"
              ref="inputFile"
              :disabled="inputs_readonly"
              @change="selectDirectory"
            ></v-file-input>
          </v-col>
        </v-row>

 

      <v-card v-if="directoryUploadCost>0 && (currentDirectory || currentDirectory_encr)" color="#840032" class="pb-0 pt-0 my-0 mx-6" height="26">
          <v-row no-gutters justify="center" align="center" class="mt-0">
   
              <p  class="white--text text-center pt-0"> {{ directoryUploadCost }} <img :src='"../assets/images/storageprotocol/cubits.svg"' height="18" alt="logo" > </p>
           
          </v-row>
        </v-card>

        <v-row no-gutters justify="center" align="center" class="mt-3 mb-1">
          <v-btn color="#2479d8" dark small @click="uploadDirectory" :disabled="inputs_readonly" >
            Upload
            <v-icon right dark>mdi-cloud-upload</v-icon>
          </v-btn>
        </v-row>


        <!-- <v-row justify="center" align="center">
          <v-col cols="4" class="pl-2 pb-4" justify="center" align="center">
            <v-btn color="#2479d8" dark small @click="upload" :disabled="inputs_readonly">
              Upload
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row> -->

      </v-card>








      <v-card v-if="fileInfos.length == 0 && viewDataSection && !showProgress && !message" class="mt-4">
        <v-card-text>
          <strong>You don't have any files yet.</strong>
        </v-card-text>
      </v-card>

      <v-card v-if="fileInfos.length > 0 && viewDataSection && !showProgress && !message" class="mt-4">
        <v-dialog
          v-model="extensionConfirm"
          persistent
          max-width="290" 
        >
          <v-card>
            <v-card-title class="headline">
              Confirmation
            </v-card-title>

            <v-card-text>
                Extend {{ expandDays }} days for {{ expandPrice }} <img :src='"../assets/images/storageprotocol/cubits.svg"' height="18" alt="logo" class="bluecubits" style="margin-top:-2px;"> ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="blue darken-1"
                text
                @click="cancelExtension"
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="confirmExtension"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-dialog
          v-model="removalConfirm"
          persistent
          max-width="290"
        >
          <v-card >
            <v-card-title class="headline">
              Delete Contract
            </v-card-title>

            <v-card-text>
              Continue with contract deletion?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="blue darken-1"
                text
                @click="cancelRemoval"
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="confirmRemoval"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        
        <v-dialog
          v-model="setownerConfirm"
          persistent
          max-width="350"
        >
          <v-card >
            <v-card-title class="marg-20">
              Set New Owner
            </v-card-title>

            <v-card-text>
              Please enter new owner address
            </v-card-text>

            <input value="0x0000000000000000000000000000000000000000" id="newowner" class="changeOwnerAddress m-2">

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="blue darken-1"
                text
                @click="cancelSetOwner"
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="confirmSetOwner"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <v-dialog
          v-model="changeownerConfirm"
          persistent
          max-width="290"
        >
          <v-card >
            <v-card-title class="marg-20">
              Confirmation
            </v-card-title>

            <v-card-text style="text-align: center;display:block;">
              Contract <strong>{{ getShortContractName() }} </strong> will change owner to<strong style="font-size:15px;"> {{this.newowner.substring(0,9) + "..." + this.newowner.substring(this.newowner.length-7)  }}</strong>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="blue darken-1"
                text
                @click="cancelChangeOwner"
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1"
                text
                @click="confirmChangeOwner"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



        <v-data-table :headers="headers" :items="fileInfos" height="34vh">
            <!-- <v-subheader>Upload Contracts</v-subheader> -->
            <template v-slot:item.contractName="{ item }">

                <!-- <v-row justify="center" align="center">
                    <div>
                        {{item.hostingContractName}}
                    </div>
                    <div>
                        <v-btn :href="'//data.storageprotocol.com/ipfs/' + item.mainContentHash" x-small target="_blank">View</v-btn>
                    </div>
                </v-row> -->
                
                    <!-- <v-btn @click="openDownload(item.mainContentHash)" x-small target="_blank" style="width:125px">{{getShortContractName2(item.hostingContractName)}}</v-btn> -->
                    <v-btn :href="'//data.storageprotocol.com/ipfs/' + item.mainContentHash" x-small target="_blank" style="width:105px">{{getShortContractName2(item.hostingContractName)}}</v-btn>
                    <!-- <iframe
                        :src="'//data.storageprotocol.com/ipfs/' + item.mainContentHash"
                        width="100%"
                        height="100"
                        frameborder="0" 
                        id="contenttest">
                      </iframe> -->

            </template>
            <template v-slot:item.contractStorageUsed="{ item }">
                <!--{{ getStorageSize(item.contractStorageUsed) }} MB-->
                <v-row justify="center" align="center">
                  {{ getStorageSize(item.contractStorageUsed) }}
                  <!-- <v-icon light>mdi-folder</v-icon> -->
                </v-row>

            </template>
            <!-- <template v-slot:item.expiration_date="{ item }">
                {{ getExpirationDate(item) }}
            </template> -->
            <template v-slot:item.hostingReplicationFactor="{ item }" style="margin-right:2px">

                     <!-- <v-row>  -->
                     <v-row justify="center" align="center"> 
                        <!--<v-col cols="4" class="pl-2" justify="center" align="center">-->

                        <!--<div>-->
                            <!-- {{ formatCubits(item.hostingReplicationFactor) }} <img :src='"../assets/images/storageprotocol/cubits.svg"' height="18" alt="logo"> -->
                            {{ item.hostingReplicationFactor }}
                        <!--</div>-->
                        <!--<div> 
                            {{ getStorageSize(item.contractStorageUsed) }} MB <v-icon light>mdi-folder</v-icon> 
                        </div>-->


                    </v-row>

            </template>

            

            <!-- <template v-slot:item.dataLink="{ item }">
                <v-btn :href="'//data.storageprotocol.com/ipfs/' + item.ipfsHash" x-small target="_blank">View</v-btn>
            </template> -->
            <!-- <template v-slot:item.addressLink="{ item }">
                <v-btn :href="'//explorer.cubitsprotocol.com/address/' + item.address" x-small target="_blank">View</v-btn>
            </template> -->
            <template v-slot:item.ExpirationTime="{ item }">
               <!-- <v-btn x-small @click="showConfirmRemoval(item)">Remove Contract</v-btn> -->


                <input type="date" name="dateofcontract" :min="getExpirationDateMin(item)" :max="getmaxDate()" :value="cubitsfs.getExpirationDate(item)" @change="showConfirmExtension(item,$event)">
   
                <!-- <v-dialog
                  ref="dialog"
                  v-model="menu2"
                  :return-value.sync="date_extend"
                  persistent
                  width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_extend"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_extend"
                  :min="getExpirationDate(item)"
                  :max="getmaxDate()"
                  :readonly="inputs_readonly"
                  @input="menu2 = false"
                  @change="showConfirmExtension(item,$event)"
                ></v-date-picker>
              </v-dialog> -->

            </template>


            <template v-slot:item.contractActions="{ item }">
                  <v-icon color="info" light @click="openDownload(item.mainContentHash)">mdi-download</v-icon>
                  <v-icon light @click="showSetOwner(item)">mdi-account</v-icon>
                  <v-icon color="error" light @click="showConfirmRemoval(item)">mdi-delete</v-icon>
   
           </template>
  

            </v-data-table>
          </v-card>

           <v-card v-if="(showProgress)" class="mt-4">
          <div>
            <div>
              <v-progress-linear
              :value="cubitsfs.upload_status"
              color="light-green"
              height="25"
   
              reactive
              
              >
                  <strong>{{ cubitsfs.upload_status }} <b-spinner style="width: 11px; height: 11px;margin-left:2px;"  label="Small Spinner" type="grow"></b-spinner> </strong>
              </v-progress-linear>
            </div>
          </div>
          </v-card>

          <v-alert v-if="message" border="left" color="grey darken-2" dark class="mt-4">
            {{ message }}
          </v-alert>
        </v-card>
    </template>

    <script>
    // import UploadService from "../services/UploadFilesService";
    // import { reject } from "q";
// import CubitsFS from "../services/CubitsFS";
// import CubitsFS from 'storageprotocol';
// import CubitsFS from "../services/cubitsfs_sdk";
// import JSZip from "../plugins/jszip";

// console.log("FFFFFF",CubitsFS)
// console.log("FFFFFF2",window)
// console.log("FFFFFF3",window.cubitsfs)
import JSZip from 'jszip'

    export default {
      name: "upload-files",
      data() {
          return {
          // networkID: '5777',
          replication_slider: 10, // same as contractReplication
          // date_create:"2018-03-20", // just placeholder?
          date_create:new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0], // just placeholder?
          // date_extend:"2018-03-20", // just placeholder?
          // menu: false,
          // modal: false,
          dialog_getting_file:false,
          menu2: false,
          cubitsfs: window.cubitsfs,
          // cubitsfs:CubitsFS,
          acc_value: 0,
          // acc_value_usd: 0,
          fileUploadCost: 0,
          directoryUploadCost: 0,
          expandDays: 0,
          inputs_readonly:false,
          expandPrice: 0,
          NEWOWNER_ERRORNAME:"ERROR CONTRACT ADDRESS",
          newowner:"ERROR CONTRACT ADDRESS",
          extensionConfirm: false,
          removalConfirm: false,
          changeownerConfirm: false,
          setownerConfirm: false,
          uploadSection: false,
          uploadDirectorySection: false,
          loginSection: true,
          viewDataSection: false,
          userSection: false,

          lastFileSelection: null,
          currentFile: undefined,
          currentDirectory: undefined,

          lastDirectorySelection: null,
          currentFile_encr: undefined,
          currentDirectory_encr: undefined,

          accountName: undefined,
          contractName: undefined,
          useEncryption: false,
          contractCallback: undefined,
          contractDuration: 1,
          contractReplication:10,
          progress: 0,
          // progressBarMessage: "nomsg",
          showProgress: false,
          supports_crypto: window.crypto !== undefined,

          // upload_files_temp_cache = [],
          // upload_files_temp_cache_encr = [],

          MESSAGE_FILE : "Please select a file",
          MESSAGE_DIRECTORY : "Please select a directory",
          MESSAGE_NAME : "Please enter upload contract name",
          MESSAGE_DURATION : "Please select upload contract duration",
          MESSAGE_REPFACTOR : "Please select upload contract replication factor",
          MESSAGE_UPLOADFAILED : "Could not upload the file",
          MESSAGE_INVALIDCONTRACTSELECTED : "Please select a valid contract",


          message: "",
          fileInfos: [],
          headers: [
              {
                text: 'Contract Name',
                align: 'start',
                sortable: false,
                value: 'contractName',
                width:'50px'
              },
              //{ text: 'Data', value: 'dataLink' },
              { text: 'Storage', value: 'contractStorageUsed' ,sortable:true,width:'50px'},
              // { text: 'Storage Cost', value: 'storagecost_cubits' },
              //{ text: 'Expiration (Date)', value: 'expiration_date' },
              { text: 'Expiration Date', value: 'ExpirationTime', width:'50px', sortable: true},
              { text: 'Copies', align: 'start', value: 'hostingReplicationFactor', sortable: true, width:'20px'},
              { text: 'Action', align: 'start', value: 'contractActions', sortable: false, width:'110px'},
          ],
        };
      },
      methods: {
        // async calculateFileUploadCost() {
        //   let size = this.useEncryption ? this.fileSize_encr : this.fileSize
        //   console.log("->size",size,this.fileSize_encr,this.fileSize,"this.useEncryption",this.useEncryption)
        //   this.cubitsfs.get_hosting_cost(size, this.contractDuration, this.contractReplication).then(response => { // change 3 later
        //     console.log(response,this.uploadDirectorySection,this.uploadSection);
        //     this.fileUploadCost = (Number(response) / 1000000000000000000).toFixed(8);
        //     return true;
        //   });
        // },
        // async calculateDirectoryUploadCost() {
        //   let size = this.useEncryption ? this.directorySize_encr : this.directorySize
        //   console.log("->size",size,this.directorySize_encr,this.directorySize,"this.useEncryption",this.useEncryption)
        //   this.cubitsfs.get_hosting_cost(size, this.contractDuration, this.contractReplication).then(response => { // change 3 later
        //     console.log(response,this.uploadDirectorySection,this.uploadSection);
        //     this.directoryUploadCost = (Number(response) / 1000000000000000000).toFixed(8);
        //     return true;
        //   });

        // },

        async calculateUploadCost() {
          let size = 1
          if (this.uploadDirectorySection) { 
            size = this.useEncryption ? this.directorySize_encr : this.directorySize
          } else {
            size = this.useEncryption ? this.fileSize_encr : this.fileSize
          }
          
          console.log("->size file",size,this.fileSize_encr,this.fileSize,"this.useEncryption",this.useEncryption)
          console.log("->size directory",size,this.directorySize_encr,this.directorySize,"this.useEncryption",this.useEncryption)
          console.log("->data", this.currentDirectory_encr,this.directorySize_encr,this.fileSize_encr,this.currentFile_encr,this.currentDirectory,this.directorySize,this.fileSize,this.currentFile)
          console.log("->data", size, this.contractDuration, this.contractReplication)


          let finalcost = await this.cubitsfs.get_hosting_cost(size, this.contractDuration, this.contractReplication).then(response => { // change 3 later
          //UploadService.calculateCost(size, this.contractDuration).then(response => {
            console.log("HOSTING COST RESPONSE:",response);
            // this.directoryUploadCost = (Number(response.data.uploadCost) / 1000000000000000000).toFixed(8);
            if (this.uploadDirectorySection) { 
              this.directoryUploadCost = (Number(response) / 1000000000000000000).toFixed(8);
            } else {
              console.log("SETTING FILECOST",response,(Number(response) / 1000000000000000000).toFixed(8))
              this.fileUploadCost = (Number(response) / 1000000000000000000).toFixed(8);
            }

          });

          console.log("finalcost",finalcost)

          return finalcost

        },





       

        showViewDataSection() {
          // this.getAccountValueUSD()
          // console.log("JSZip",JSZip)
          this.loginSection = false;
          this.uploadSection = false;
          this.uploadDirectorySection = false;
          this.viewDataSection = true;
          this.userSection = true;
          this.contractCallback = undefined;
          this.accountName = undefined;
          this.contractName = undefined;
          // this.contractDuration = 1;
          // this.contractReplication = 10;
          // this.lastFileSelection = null
          // this.lastDirectorySelection = null
          // this.currentFile = undefined;
          // this.currentFile_encr = undefined;
          // this.currentDirectory = undefined;
          // this.currentDirectory_encr = undefined;
          // this.fileUploadCost = 0;
          // this.directoryUploadCost = 0;
          this.showProgress = false;
          this.inputs_readonly = false;
          this.message = "";
          // this.resetFile()
          // this.resetVars()
        },
        showUploadSection() {
          if (this.viewDataSection == false) { // don't reset upload file if we came from view data
            this.resetFileLoad()
          }

          this.loginSection = false;
          this.uploadSection = true;
          this.uploadDirectorySection = false;
          this.viewDataSection = false;
          this.userSection = true;
          this.contractCallback = undefined;
          this.accountName = undefined;
          this.inputs_readonly = false;

          // if(!is_reset) {
          //   this.resetFileLoad()
          // }
          

          this.message = "";
        },
        showUploadDirectorySection() { // don't reset upload file if we came from view data

          if (this.viewDataSection == false) {
            this.resetFileLoad()
          }

          this.loginSection = false;
          this.uploadDirectorySection = true;
          this.uploadSection = false;
          this.viewDataSection = false;
          this.userSection = true;
          this.contractCallback = undefined;
          this.accountName = undefined;
          this.inputs_readonly = false;

          // if(!is_reset) {
          //   this.resetFileLoad()
          // }
          

          this.message = "";
        },
        showConfirmExtension(_contract, _event) {
        // showConfirmExtension(_contract, _value) {
          
          if (isNaN(_event.target.value) == true) { // checks against "clear" button inside calender
            console.log("_event.target.value",_event.target,_event.target.value,isNaN(_event.target.value),_contract)
            this.contractCallback = _contract;
            this.contractDuration = 1;
            this.contractReplication = 10;
            this.extensionConfirm = true;

            let _currentEndDate_unix = this.cubitsfs.getExpirationDate_UNIX(_contract)
            //let _currentEndDate = new Date(_currentEndDate_unix).toISOString().split('T')[0]
            //let days_extend = parseInt((new Date(document.getElementById('extend_contract_date').value).getTime() - _currentEndDate_unix) / (60 * 60 * 24 * 1000)) + 1
            let days_extend = parseInt((new Date(_event.target.value).getTime() - _currentEndDate_unix) / (60 * 60 * 24 * 1000)) + 1
            // let days_extend = parseInt((new Date(_value).getTime() - _currentEndDate_unix) / (60 * 60 * 24 * 1000)) + 1


            this.expandDays = days_extend

            // this.cubitsfs.get_hosting_cost(parseInt(_contract["contractStorageUsed"]), days_extend, parseInt(_contract["hostingReplicationFactor"])).then((_uploadCost) => {
            //   this.expandPrice = this.formatCubits(_uploadCost)
            // })
            console.log("_currentEndDate_unix",_contract,_currentEndDate_unix)
            this.cubitsfs.get_extend_cost(_contract, days_extend).then((_uploadCost) => {
              this.expandPrice = this.formatCubits(_uploadCost)
            })
            //this.expandPrice = 0.0000532

            console.log("days_extend", days_extend, _event)
            // console.log("days_extend", days_extend, _value)
          } else {
            _event.target.value = this.cubitsfs.getExpirationDate(_contract); // reset oct 2023
          }


            
        },
        confirmExtension() {
          this.extensionConfirm = false;
          this.extend(); 
        },
        cancelExtension() {
          this.extensionConfirm = false;
          this.contractCallback = undefined;
          this.contractDuration = 1;
          this.contractReplication = 10;
        },
        showConfirmRemoval(_contract) {
          this.contractCallback = _contract;
          this.removalConfirm = true;
        },
        confirmRemoval() {
          this.removalConfirm = false;
          this.remove();
        },
        cancelRemoval() {
          this.removalConfirm = false;
          this.contractCallback = undefined;
        },
        showSetOwner(_contract) {
          this.contractCallback = _contract;
          this.setownerConfirm = true;
        },
        confirmSetOwner() {
          var newowner = document.getElementById('newowner').value
          if (this.cubitsfs.utility.validate_wallet_address(newowner)) {
            this.newowner = this.cubitsfs.utility.validate_wallet_address(newowner)
            this.setownerConfirm = false;
            this.setowner();
            this.showConfirmChangeOwner();
          } else {
            this.newowner = this.NEWOWNER_ERRORNAME
            document.getElementById('newowner').value = this.NEWOWNER_ERRORNAME
          }

        },
        cancelSetOwner() {
          this.setownerConfirm = false;
          this.contractCallback = undefined;
          this.newowner = "ERROR";
        },
        showConfirmChangeOwner() {
          this.changeownerConfirm = true;
        },
        confirmChangeOwner() {
          this.changeownerConfirm = false;
          this.changeowner();
        },
        cancelChangeOwner() {
          this.changeownerConfirm = false;
          this.contractCallback = undefined;
        },
        setKey(key) {
          // this.progress = 0;
          this.cubitsProtocolKey = key;
        },
        setAccountName(name) {
          // this.progress = 0;
          this.accountName = name;
        },
        getCubitsPrice() {
          return new Promise((resolve) => {
            let xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=usd", false );
            xmlHttp.send( null );
            let jsonData = JSON.parse(xmlHttp.responseText)
            console.log("cubits_price","--",jsonData,jsonData["ether-1"]["usd"])
            if (jsonData["ether-1"] !== undefined) {
              resolve(parseFloat(jsonData["ether-1"]["usd"]))
            } else {
              resolve(-1)
            }
            // return xmlHttp.responseText;
          })
        },

        // downloadFile(_base64) {
        downloadFile(_base64) {
          // let data = Uint8Array.from(this.cubitsfs.encryption.base64_to_buf(_base64[1])); // content
          // let data = Uint8Array.from(_base64[1]); // content
          // var content = new Blob([data.buffer], { type: attach.contentType });
          // var content = new Blob([data.buffer], { type: "txt" });
          // let content = new Blob([data.buffer]);
          // let content = new Blob([_base64[1]]); 
          // let content = new Blob([_base64[1]], {
          //     type: 'text/plain'
          // });

          // let encodedUri = window.URL.createObjectURL(content);
          let encodedUri = window.URL.createObjectURL(_base64[1]);
          let link = document.createElement("a");

          link.setAttribute("href", encodedUri);
          link.setAttribute("download", _base64[0]); // name

          link.click();
          this.message = "";
        },

        downloadFiles(_total_files) {
          console.log("JSZip",JSZip)
            let _this = this
            this.zipFiles(_total_files).then(function(zip) {
              console.log("zip2",zip)
              zip.generateAsync({type:"blob"}).then(function(content) {
                  // see FileSaver.js
                  // saveAs(content, "example.zip");
                  let encodedUri = window.URL.createObjectURL(content);
                  let link = document.createElement("a");

                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", "example.zip"); // name

                  link.click();

                  _this.message = "";
              });
            })
        },

        async zipFiles(_total_files) {
          var zip = new JSZip();
          var _count = 0
          // return new Promise((resolve) => {
            for (var i = 0; i <= _total_files.length - 1; i++) {
              var _file = _total_files[i]

              // img.file(_file[1], _file[2], {base64: true});
              _count ++
              if (_file[0] == 1) {
                var _decryptedRAW = await this.cubitsfs.encryption.FullDECRYPT(_file[2])
                // this.cubitsfs.encryption.FullDECRYPT(_file[2]).then((_decryptedRAW) => {
                  console.log("ADDING DECYRPTED CONT")
                  let path1 = _file[1]
                  let path2 = _decryptedRAW[0]
                  let last_index = path1.lastIndexOf('/')
                  let path_new = ""
                  if (last_index) {
                    path_new = path1.slice(0,last_index+1) + path2 //subroot file
                  } else {
                    path_new = path2 // rootfile
                  }
                  
                  console.log("path1",path1,"path2",path2,"path_new",path_new)

                  // let data = Uint8Array.from(this.cubitsfs.encryption.base64_to_buf(_base64[1])); // content
                  // let content = new Blob([data.buffer]);

                  // zip.file(path_new, _decryptedRAW[1]);
                  // zip.file(path_new, this.cubitsfs.encryption.base64_to_buf(_decryptedRAW[1]));
                  zip.file(path_new, _decryptedRAW[1]);
                // })
              } else {
                console.log("NOT ENCRYPTED",_file)
                // let arraybuf = new ArrayBuffer(Uint8Array.from(_file[2]))
                // console.log("arraybuf",arraybuf)
                // zip.file(_file[1],arraybuf.buffer);

                // new TextEncoder().encode("This is a string converted to a Uint8Array")
                // zip.file(_file[1],(new TextEncoder().encode(_file[2])).buffer);
                // zip.file(_file[1],Uint8Array.from(atob(btoa(String.fromCharCode(..._file[2]))), (c) => c.charCodeAt(null)).buffer);
                // zip.file(_file[1],Uint8Array.from(btoa(String.fromCharCode(..._file[2]))).buffer);
                // zip.file(_file[1],Uint8Array.from(btoa(String.fromCharCode(..._file[2]))));
                // zip.file(_file[1],btoa(String.fromCharCode(..._file[2])));
                // zip.file(_file[1],URL.createObjectURL(new Blob([Uint8Array.from(_file[2]).buffer])));
                // zip.file(_file[1],URL.createObjectURL(Uint8Array.from(_file[2]).buffer));
                // console.log(new TextDecoder(_file[2]))
                zip.file(_file[1],_file[2], {binary:true});
                // zip.file(_file[1],_file[2]);
                // zip.file(_file[1],new Blob([_file[2]]));
                // zip.file(_file[1],Uint8Array.from(_file[2]).buffer);
                // zip.file(_file[1],Uint8Array.from(_file[2]).buffer);
                // zip.file(_file[1], new Blob([Uint8Array.from(_file[2])]));
   
              }
              if (_count == _total_files.length ) {
                console.log("--------------------------------")
                console.log("zip1",zip)
                return zip
              }

            }
          // })

        },

        handleFiles(_total_files) {
          if (_total_files.length == 1) {
            console.log("single file result",_total_files[0])
            // if (_is_encrypted) {
            if (_total_files[0][0] == 1) { // encryption active on file
              this.cubitsfs.encryption.FullDECRYPT(_total_files[0][2]).then((_decryptedRAW) => {

                console.log("_decryptedRAW",_decryptedRAW)
                this.downloadFile([_decryptedRAW[0],new Blob([_decryptedRAW[1]])])
                // this.downloadFile(_decryptedRAW)

              })
            } else {
              // this.downloadFile([_total_files[0][1],Uint8Array.from(_total_files[0][2]).buffer]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??
              console.log("DOWNLOAD 2")
              // this.downloadFile([_total_files[0][1],Uint8Array.from(_total_files[0][2]).buffer]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??
              
              // BINARY TO UINT8
              // let d = _total_files[0][2];
              // let l = d.length;
              // let array = new Uint8Array(l);
              // for (var i = 0; i < l; i++){
              //     array[i] = d.charCodeAt(i);
              // }

              // this.downloadFile([_total_files[0][1],array]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??

              // this.downloadFile([_total_files[0][1],Uint8Array.from(_total_files[0][2])]) // DOES THIS ALWAYS WORK WITH UINT8ARRAY ??
              this.downloadFile([_total_files[0][1],_total_files[0][2]])
              // this.downloadFile(_total_files[0][1],_total_files[0][2])
            }
          
          } else {
            console.log("create zip download for multiple files",_total_files)


            // var blob = new Blob( ["test"], { type: "text/plain" } );
            console.log("JSZip",JSZip)
            

            // zip.file("Hello.txt", "Hello World\n");

            // const img = zip.folder("images");

            // var zip = this.zipFiles(_total_files)
            // this.zipFiles(_total_files).then(function(zip) {
            //   console.log("zip2",zip)
            //   zip.generateAsync({type:"blob"}).then(function(content) {
            //       // see FileSaver.js
            //       // saveAs(content, "example.zip");
            //       let encodedUri = window.URL.createObjectURL(content);
            //       let link = document.createElement("a");

            //       link.setAttribute("href", encodedUri);
            //       link.setAttribute("download", "example.zip"); // name

            //       link.click();
            //   });
            // })

            this.downloadFiles(_total_files);

            // zip.file( 'file.txt' , blob );

            // zip.generateAsync( { type: 'blob', compression: 'DEFLATE' } )
            // .then( function( zipFile ){ 

            //     console.log(zipFile)

            // }, function( error ){ 

            //     console.log( 'Error in compression' ,error);

            // } );



          }
        },



        openDownload(CID_HASH) {
          this.dialog_getting_file = true
          this.message = "Downloading CID: "+CID_HASH

          // this.collectDownload(CID_HASH)
          var _this = this
          setTimeout(function () { // why timeout ?
            _this.collectDownload(CID_HASH)
          }, 1000);
        },

        // async runDownload(CID_HASH) {
        //   this.collectDownload(CID_HASH)
        // },



        
        async collectDownload(CID_HASH) {
          // this.message = "Reading your files from IPFS..."

          this.message = "Downloading CID: "+CID_HASH

          let [_is_encrypted,_total_files] = await this.cubitsfs.ipfs_handler.getRawIPFScontent(CID_HASH)
          console.log("_is_encrypted",_is_encrypted)
          console.log("_total_files",_total_files)


          if (_is_encrypted) { // encrypted files never use array higher than uint8
            this.updateEncryption().then((_worked) => {
                if (_worked) {
                  this.message = "Decrypting CID: "+CID_HASH
                  console.log("getting dec key worked",_worked)
                  this.handleFiles(_total_files)
                } else {
                  console.log("error with getting decryption key")
                }
            })

          } else { // careful with file encodings... larger than uint8 latin.-1 etc
            this.handleFiles(_total_files)
          }

          this.dialog_getting_file = false
          

          // console.log(_fileRAW)

          // if (_fileRAW.substring(0,11) == "cubitsencode(") {
          // if (_fileRAW.substring(0,9) == "cubitsname(") {

          // console.log("DECRYPTIONLOG:SINGLE FILE")

          // this.updateEncryption().then((_worked) => {
          //     if (_worked) {

          //       this.cubitsfs.encryption.FullDECRYPT(_fileRAW).then((_decryptedRAW) => {

          //         console.log("_decryptedRAW",_decryptedRAW)

          //       })
          //     }
          // })
      
                    // this.downloadFile(_decryptedRAW)
                    // x.document.open().write(atob(_decryptedRAW));
                    // x.document.body.innerHTML

          // } else {
            // console.log("DECRYPTIONLOG:FOLDER TYPE")

            // var x=window.open();
            // x.document.open().write(_fileRAW);
            // x.document.body.innerHTML
          // }

          // return new Promise((resolve) => {

          // })
        },
        loadFile(file) {
          console.log("LOADING FILE!!!!!!")
          this.cubitsfs.loadFileObject(file,this.useEncryption).then((readstream) => {
            console.log("LOADING FILE FINISHED!!!!!!",readstream,readstream[0],readstream[1])
            if (this.useEncryption) {
              if (this.uploadDirectorySection) { 
                this.currentDirectory_encr = readstream[0]
                this.directorySize_encr = readstream[1];
              } else {
                this.currentFile_encr = readstream[0]
                this.fileSize_encr = readstream[1];
              }

            } else {
              console.log("B")
              if (this.uploadDirectorySection) { 
                this.currentDirectory = readstream[0]
                this.directorySize = readstream[1]
              } else {
                this.currentFile = readstream[0]
                this.fileSize = readstream[1];
              }

            }

            // this.calculateFileUploadCost();
            this.calculateUploadCost();

          })
        },
        selectFile(file) {
          // this.progress = 0;
          if (file) {
            // this.currentFile = file;

            this.lastFileSelection = file;

            console.log("START ENCRYPTION ON SINGLE FILE")
            this.loadFile(file)

            // if (this.message != "") {
            if (this.message == this.MESSAGE_FILE) {
              this.message = "";
            }
           

          } else {
            console.log("FILE WAS DELETED BY USER?")
            this.fileUploadCost = 0;
            this.currentFile = undefined;
            this.currentFile_encr = undefined;
            this.lastFileSelection = null;
          }

        },
        selectDirectory(directory) {
          console.log(directory);

          if (directory) {
            // this.progress = 0;
            // this.currentDirectory = directory;

            this.lastDirectorySelection = directory;
            console.log("START ENCRYPTION ON SINGLE DIRECTORY")
            this.loadFile(directory)

            if (this.message == this.MESSAGE_DIRECTORY) {
              this.message = "";
            }

          } else {
            console.log("DIRECTORY WAS DELETED BY USER?")
            this.directoryUploadCost = 0;
            this.currentDirectory = undefined;
            this.currentDirectory_encr = undefined;
            this.lastDirectorySelection = null;
          }

        },
        setContractName(name) {
          // this.progress = 0;
          this.contractName = name;
          if (this.message == this.MESSAGE_NAME) {
            this.message = "";
          }
        },
        updateEncryption() {
          return new Promise((resolve) => {
            if (!this.cubitsfs.encryption.hasPassword()) {
              let encr_key = 'enc_key_'+this.cubitsfs.accounts[0]
              if (localStorage.getItem(encr_key) === null) {
                this.cubitsfs.request_sign("metamask","create encryption key").then((new_password) => {
                  new_password = new_password.substr(2,66) // fix for shorter length

                  console.log("new_password",new_password)
                  console.log("encr_key",encr_key)
                  console.log("localStorage.getItem(encr_key)",localStorage.getItem(encr_key))

                  this.cubitsfs.encryption.setPassword(new_password).then((pw_result) => {
                    if (pw_result) {
                      localStorage.setItem(encr_key, new_password)
                      resolve(true)
                    }
                })


  
                })
              } else {

                this.cubitsfs.encryption.setPassword(localStorage.getItem(encr_key)).then((pw_result) => {
                    if (pw_result) {
                      resolve(true)
                    }
                })
                
                
              }
            } else {
              console.log("ALREADY HAS PASSWORD")
              // reject("FIXLATER")
              resolve(true)
            }
          })

        },
        setEncryption() {
          this.useEncryption = true;
          console.log("SETTING ENCRYPTION",this.lastFileSelection,this.lastDirectorySelection)
          if (this.lastFileSelection) {
            if (!this.currentFile_encr) { // only encrypt once
              console.log("START ENCRYPTION ON SINGLE FILE")
              this.loadFile(this.lastFileSelection)
            } else {
              // this.calculateFileUploadCost(); // recalc the directory due to rep factor?
              this.calculateUploadCost();
            }


          } else if (this.lastDirectorySelection) {
            console.log("START ENCRYPTION ON DIRECTORY")
            if (!this.currentDirectory_encr) { // only encrypt once
              this.loadFile(this.lastDirectorySelection)
            } else {
              // this.calculateDirectoryUploadCost(); // recalc the directory due to rep factor?
              this.calculateUploadCost();
            }

          }

        },
        activateEncryption(option) {
          console.log("ENCRYPTION IS ACTIVTED!",option)
         
          if (option == false) {
            this.useEncryption = false;
            if (this.lastFileSelection) {

              // this.fileSize = this.lastFileSelection.size;
              // this.calculateFileUploadCost();

              if (!this.currentFile) {
                console.log("LOAD NON-ENCRYPTION SINGLE FILE")
                this.loadFile(this.lastFileSelection)
              } else {
                this.calculateUploadCost();
              }

            } else if (this.lastDirectorySelection) {
              // var size = 0;
              // for (var i = 0; i <= this.lastDirectorySelection.length - 1; i++) {
              //   size += this.lastDirectorySelection[i].size;
              //   if(i == (this.lastDirectorySelection.length - 1)) {
              //     this.directorySize = size;
              //     this.calculateDirectoryUploadCost();
              //   }
              // }

              if (!this.currentDirectory) {
                console.log("LOAD NON-ENCRYPTION DIRECTORY")
                this.loadFile(this.lastDirectorySelection)
              } else {
                this.calculateUploadCost();
              }
            }
              
          } else {
            if (!this.cubitsfs.encryption.hasPassword()) {
              let encr_key = 'enc_key_'+this.cubitsfs.accounts[0]
              if (localStorage.getItem(encr_key) === null) {
                this.cubitsfs.request_sign("metamask","create encryption key").then((new_password) => {
                  console.log("new_password",new_password)
                  if (new_password) {
                    new_password = new_password.substr(2,66) // fix for shorter length

                    console.log("new_password",new_password)
                    console.log("encr_key",encr_key)
                    console.log("localStorage.getItem(encr_key)",localStorage.getItem(encr_key))

                    this.cubitsfs.encryption.setPassword(new_password).then((pw_result) => {
                      if (pw_result) {
                        console.log("LOLAAAOLL",pw_result)
                        localStorage.setItem(encr_key, new_password)


                        this.setEncryption()
                      } else {
                        console.log("UNCHECK BOX")
                        // option = false
                        document.getElementById('encryption_box').click()
                      }

                    })
                  } else {
                    console.log("UNCHECK BOX")
                    // option = false
                    document.getElementById('encryption_box').click()
                  }
      

                })
              } else {
                this.cubitsfs.encryption.setPassword(localStorage.getItem(encr_key)).then((pw_result) => {
                  if (pw_result) {
                    this.setEncryption()
                  }

                })
         
              }
            } else {
              this.setEncryption()
            }
          }
        },
        // setContractDuration(_event) {
        setContractDuration(_value) {
          console.log("_value",_value)
          // let days_deploy = parseInt((new Date(_event.target.value).getTime() - Date.now()) / (60 * 60 * 24 * 1000)) + 1 // should return how many days into future from now
          let days_deploy = parseInt((new Date(_value).getTime() - Date.now()) / (60 * 60 * 24 * 1000)) + 1 // should return how many days into future from now

          // this.date_create = new Date(new Date(_value).getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          // this.date_create = "2018-03-20"
          console.log(new Date(new Date(_value).getTime()).toISOString().split('T')[0])
          // this.progress = 0;
          this.contractDuration = days_deploy;
          this.calculateUploadCost();

          if (this.message == this.MESSAGE_DURATION) {
            this.message = "";
          }

        },
        // setContractReplication(_event) {
        setReplication(_value) {
          // console.log(_event)
          // console.log(_event.value)
          // let repl_factor = parseInt(_event.target.value)
          let repl_factor = parseInt(_value)
          this.contractReplication = repl_factor;
          this.calculateUploadCost();

          if (this.message == this.MESSAGE_REPFACTOR) {
            this.message = "";
          }

        },
        getContractName() {
          console.log("this.contractCallback",this.contractCallback)
          return this.contractCallback ? this.contractCallback.hostingContractName : "NO CONTRACT SELECTED"
        },
        getShortContractName() {
          return this.getContractName().length > 18 ? this.getContractName().slice(0,15)+"..." : this.getContractName()
        },
        getShortContractName2(c_name) {
          return c_name.length > 18 ? c_name.slice(0,15)+"..." : c_name
        },
        getAccountValue() {
            return this.acc_value
        },
        // getAccountValueUSD() {
          
        //   this.getCubitsPrice().then((cubits_price) => {
        //     console.log("cubits_price",cubits_price)
        //     if (cubits_price !== -1) {
        //       console.log("cubits_price 2",this.formatCubits(this.getAccountValue()) * cubits_price)
        //       // return "(" + (this.formatCubits(this.getAccountValue()) * cubits_price) + " USD)"
        //       this.acc_value_usd =  "(" + (this.formatCubits(this.getAccountValue()) * cubits_price) + " USD)"
        //     }
        //   })
        //   return 0.01

        // },

        // is_using_encryption() {
        //   return this.useEncryption
        // },
        formatCubits(_amt) {
            return (parseInt(_amt) / 1000000000000000000).toFixed(8)
            // let _CUBITS = (parseInt(_amt) / 1000000000000000000).toFixed(8)
            // console.log(this.cubitsprice)
            // return _CUBITS + " " +parseFloat(_CUBITS)*this.cubitsprice
        },
        displayCubits(_amt) {
          return (parseInt(_amt) / 1000000000000000000).toFixed(8).substr(0,9)
        },
        formatUSD(_amt) {
            return (parseInt(_amt) / 1000000000000000000).toFixed(2)
        },
        getStorageSize(_size) {
          let newsize = parseInt(_size)
          console.log(_size)
          if (newsize < 1000) {
            return parseInt(_size).toFixed(2) + " B"
          } else if (newsize < 1000000) {
            return (parseInt(_size) / 1000).toFixed(2) + " KB"
          } else if (newsize < 1000000000) {
            return (parseInt(_size) / 1000000).toFixed(2) + " MB"
          } else {
            return (parseInt(_size) / 1000000000).toFixed(2) + " GB"
          }
            
        },
        getminDate() {
          // this.date_create = new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          return new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          // return (new Date(Date.now() + 60 * 60 * 24 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        },
        getmaxDate() { //date_extend
          // console.log("new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 5).toISOString().split('T')[0]",new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 5).toISOString().split('T')[0])
          // return new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 5).toISOString().split('T')[0]
          return new Date(new Date().getTime() + 10 * 365.25 * 60 * 60 * 24 * 1000).toISOString().split('T')[0]
          // return (new Date(Date.now() + 10 * 365.25 * 60 * 60 * 24 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        },
        getExpirationDateMin(_contract) {
            //.deployedDaysLength
            
            // let _currentEndDate_unix = _contract["creationTimestamp"] * 1000 + _contract["deployedDaysLength"] * 60 * 60 * 24 * 1000
            let _currentEndDate_unix = _contract["creationTimestamp"] * 1000 + (parseInt(_contract["deployedDaysLength"])+1) * 60 * 60 * 24 * 1000
            let _currentEndDate = new Date(_currentEndDate_unix).toISOString().split('T')[0]
            console.log("_currentEndDate",_currentEndDate)
            // this.date_extend = _currentEndDate
            return _currentEndDate
        },
        getCurrentDate(_contract) {
              //.deployedDaysLength
              let _currentEndDate_unix = _contract["creationTimestamp"] * 1000 + _contract["deployedDaysLength"] * 60 * 60 * 24 * 1000
              let _currentEndDate = new Date(_currentEndDate_unix).toISOString().split('T')[0]
            return _currentEndDate
        },
        resetFile() {
          console.log("->resetFile")
          this.currentFile = undefined;
          this.currentFile_encr = undefined;
          this.currentDirectory = undefined;
          this.currentDirectory_encr = undefined;
          this.fileUploadCost = 0;
          this.directoryUploadCost = 0;
        },
        resetVars() {
            console.log("->resetVars")
            this.lastDirectorySelection = null;
            this.lastFileSelection = null;
            this.cubitsProtocolKey = undefined;
            this.contractName = undefined;
            this.contractCallback = undefined;
            this.contractDuration = 1;
            this.contractReplication = 10;
            this.accountName = undefined;
            this.inputs_readonly = false;
            this.useEncryption = false;
            this.resetFile()

        },
        checkJson(data) {
          try {
            return (JSON.parse(data) && !!data);
          } catch (e) {
            return false;
          }
        },
        resetFileLoad() {
          console.log("->resetFileLoad")
          if (this.$refs.inputFile) {
            this.$refs.inputFile.reset();
          }
            // this.contractDuration = 1;
            // this.contractReplication = 10;
            // this.contractName = undefined;
            // this.lastFileSelection = null
            // this.lastDirectorySelection = null
            // this.currentFile = undefined;
            // this.currentFile_encr = undefined;
            // this.currentDirectory = undefined;
            // this.currentDirectory_encr = undefined;
        },
        logout() {
            console.log("ADD SIWTCH ACCOUNT HERE!")
            this.loginSection = true;
            this.userSection = false;
            this.userSection = false;
            this.uploadSection = false;
            this.useEncryption = false;
            this.uploadDirectorySection = false;
            this.fileInfos = [];
            this.contractAddress = undefined;
            this.accountName = undefined;
            this.contractName = undefined;
            this.lastDirectorySelection = null;
            this.lastFileSelection = null;
            this.currentFile = undefined;
            this.currentFile_encr = undefined;
            this.currentDirectory = undefined;
            this.currentDirectory_encr = undefined;
            this.message = "";
        },
        login() {
          this.showProgress = false;
          // this.progress = 0;

          this.cubitsfs.connect_metamask().then((metamask_is_connected) => {
                console.log("metamask_is_connected", metamask_is_connected)
                this.cubitsfs.check_account().then((metamask_accounts) => {
                    console.log("metamask_accounts", metamask_accounts)
                    if (metamask_accounts == -1) {
                        console.log("SWITCH NOW")
                        console.log("SWITCH NOW")
                        this.message = "Please connect to metamask";


                    } else if (metamask_accounts.length >= 0) {
                      this.message = "login in account.";
                      this.cubitsfs.login_account().then((metamask_accounts) => {
                        
                        if (metamask_accounts.length > 0) {
                          console.log("EMPTY")
                          this.message = "logged into account " + metamask_accounts[0];
                          this.message = "Retrieving existing contracts";
                          //UploadService.list(this.cubitsProtocolKey).then(response => {
                          this.message = "";
                          //this.fileInfos = response.data;

                          this.cubitsfs.get_user_cubits().then((acc_value) => {
                            this.acc_value = acc_value
                            console.log("acc_value",acc_value)
                            // this.getCubitsPrice().then((cubits_price) => {
                            //     console.log("cubits_price",cubits_price)
                            //     if (cubits_price !== -1) {
                            //       console.log("cubits_price 2",this.formatCubits(acc_value) * cubits_price)
                            //       // return "(" + (this.formatCubits(this.getAccountValue()) * cubits_price) + " USD)"
                            //       // this.acc_value_usd =  "(~" + this.formatUSD(acc_value * cubits_price) + " $)"
                            //       this.acc_value_usd =  this.formatUSD(acc_value * cubits_price)
                            //     }
                            //   })
                          })

                          this.cubitsfs.get_user_files(metamask_accounts[0]).then((file_ids) => {


                              console.log("files_ids:",file_ids)

                              //this.fileInfos = [{
                              //    address: "0x98598345",
                              //    data: "testdata",
                              //    ipfsHash: "testhash",
                              //    uploadBlock: 523523,
                              //    expirationBlock: 6436346
                              //}]
                              this.fileInfos = file_ids

                          })
                          //this.showUploadSection()
                          this.showViewDataSection()

                          //this.fileInfos = [{ "1": ["test", 123, 1245, 5235, 5235, 325] }]

                          //})
                      }
                    })
                  }
                })
            })


        },
        upload() {
          // this.progress = 0;
          if (!this.currentFile && !this.currentFile_encr) {
            this.message = this.MESSAGE_FILE;
            return;
          }

          if (!this.contractName) {
            this.message = this.MESSAGE_NAME;
            return;
          }
          if (!this.contractDuration) {
            this.message = this.MESSAGE_DURATION;
            return;
          }
          if (!this.contractReplication) {
            this.message = this.MESSAGE_REPFACTOR;
            return;
          }
          this.showProgress = true;
          this.message = "";

          // var self = this;

          // self.updateProgressBar("Waiting for user confirmation4...");

          // var progressInterval;
          // var loaded = 0;
          console.log("SENDING TO UPLOADS:",this.currentFile, this.contractName, this.contractDuration, this.contractReplication)

          let targetFile = this.currentFile_encr

          if(!targetFile || this.useEncryption == false) {
            console.log("UPLOADING NON-ENCRYPTED FILE")
            targetFile = this.currentFile
          } else {
            console.log("UPLOADING ENCRYPTED FILE")
          }

          console.log("this.currentFile_encr",this.currentFile_encr)
          console.log("this.currentFile",this.currentFile)
          console.log("targetFile",targetFile)

          this.cubitsfs.uploadFiles(targetFile, this.contractName, this.contractDuration, this.contractReplication).then((response) => {
            console.log("-> accept/reject reponse: ", response);
            // this.showViewDataSection()

            console.log("this.fileInfos",this.fileInfos)
            this.cubitsfs.get_contract_by_id(response.contract_id).then((_contract) => {
              console.log(_contract)
              this.fileInfos.push(_contract)
              this.showViewDataSection()
            })


            // var _this = this
            // setTimeout(function () { // why timeout ?
            //   _this.showViewDataSection()
            // }, 1000);

          })
          .catch((error) => {
            console.log(error)
            this.showProgress = false;
            // this.progress = 0;
            this.message = this.MESSAGE_UPLOADFAILED;
            
            var _this = this
            setTimeout(function () { // why timeout ?
              // _this.resetVars()
              // _this.resetFileLoad()
              // _this.showViewDataSection() 
              _this.showUploadSection(true) 
            }, 2000);

          });
          this.inputs_readonly = true;

        },
        uploadDirectory() {
          this.showProgress = false;
          // this.progress = 0;
          if (!this.currentDirectory && !this.currentDirectory_encr) {
            this.message = this.MESSAGE_DIRECTORY;
            return;
          }
          if (!this.contractName) {
            this.message = this.MESSAGE_NAME;
            return;
          }
          if (!this.contractDuration) {
            this.message = this.MESSAGE_DURATION;
            return;
          }
          if (!this.contractReplication) {
            this.message = this.MESSAGE_REPFACTOR;
            return;
          }

          

          this.showProgress = true;
          this.message = "";

          // var self = this;
          // var progressInterval;
          // var loaded = 0;

          let targetFile = this.currentDirectory_encr

          if(!targetFile || this.useEncryption == false) {
            console.log("UPLOADING NON-ENCRYPTED DIRECTORY")
            targetFile = this.currentDirectory
          } else {
            console.log("UPLOADING ENCRYPTED DIRECTORY")
          }

          // UploadService.uploadDirectory(this.currentDirectory, this.cubitsProtocolKey, this.contractName, this.contractDuration, this.contractReplication, (event) => {
          this.cubitsfs.uploadFiles(targetFile, this.contractName, this.contractDuration, this.contractReplication).then((response) => {
            console.log("-> accept/reject reponse: ", response);
            // this.showViewDataSection()

            console.log("this.fileInfos",this.fileInfos)
            this.cubitsfs.get_contract_by_id(response.contract_id).then((_contract) => {
              console.log(_contract)
              this.fileInfos.push(_contract)
              this.showViewDataSection()
            })


            // var _this = this
            // setTimeout(function () { // why timeout ?
            //   _this.showViewDataSection()
            // }, 1000);

          })
          .catch((error) => {
            console.log(error)
            this.showProgress = false;
            // this.progress = 0;
            // this.message = "Could not upload the file";
            this.message = this.MESSAGE_UPLOADFAILED;
            
            var _this = this
            setTimeout(function () { // why timeout ?
              // _this.resetVars()
              // _this.showViewDataSection()
              _this.showUploadDirectorySection(true) 
            }, 2000);

          });
        },
        extend() {
          // this.progress = 0;
          if (!this.contractCallback) {
            this.message = this.MESSAGE_INVALIDCONTRACTSELECTED;
            return;
          }

          this.showProgress = true;
          this.message = "";
          //var loaded = 0;
            // var self = this;

            // self.updateProgressBar("Waiting for user confirmation1...");
            
  
            var _this = this;
            this.message = "Confirming contract extension";

            //UploadService.extend(this.cubitsProtocolKey, this.contractCallback, this.contractDuration).then((response) => {
            this.cubitsfs.extendContract(this.contractCallback["_index"], parseInt(this.contractCallback["contractStorageUsed"]), this.expandDays,parseInt(this.contractCallback["hostingReplicationFactor"])).then((response) => {
              console.log("-> accept/reject reponse: ", response);
              //this.message = response.data.message;
                //this.message = "Retrieving existing contracts";
                
                //loaded = 100;
          
                // this.updateProgressBar("extended!");
                //this.fileInfos = response.data;
                this.message = "Contract extension successful";
                
                setTimeout(function(){
                    _this.message = "";
                    _this.login()
                    _this.resetVars()
                    _this.showProgress = false;
                }, 1000);

   
              
            })
            .catch(() => {
                    // this.updateProgressBar("canceled!");

                  this.showProgress = false;
                  // this.progress = 0;
                  this.message = "Contract extension failed";
                  this.resetVars()
                  var _this = this
                  setTimeout(function () { // why timeout ?
                    _this.showViewDataSection()
                  }, 2000);
            });
        },
        remove() {
          // this.progress = 0;

          if (!this.contractCallback) {
            this.message = this.MESSAGE_INVALIDCONTRACTSELECTED;
            return;
            }

          var _this = this;
          this.showProgress = true;
          this.message = "";

          // var self = this;
          // self.updateProgressBar("Waiting for user confirmation2...");
          

          this.message = "Confirming contract deletion";
          //UploadService.remove(this.cubitsProtocolKey, this.contractCallback).then((response) => {
          this.cubitsfs.deleteContract(this.contractCallback["_index"]).then((response) => {
              console.log("-> accept/reject reponse: ", response);
            
              // this.updateProgressBar("deleted!");
              //this.message = response.data.message;
              this.message = "Contract deletion successful";


              setTimeout(function () { // why timeout ?
                  _this.message = "";
                  _this.login()
                  _this.resetVars()

                  _this.showProgress = false;
              }, 1000);



            })
            .catch(() => {
              this.showProgress = false;
              // this.progress = 0;
              this.message = "Contract deletion failed";
              this.resetVars()
              var _this = this
              setTimeout(function () { // why timeout ?
                _this.showViewDataSection()
              }, 2000);

            });
        },
        removeAll() {

          var _this = this;
          this.showProgress = true;
          this.message = "";

          // self.updateProgressBar("Waiting for user confirmation2...");

          this.message = "Confirming contract deletion";
          //UploadService.remove(this.cubitsProtocolKey, this.contractCallback).then((response) => {
          // CubitsFS.deleteContract(this.contractCallback["_index"]).then((response) => {
            this.cubitsfs.deleteAllContracts().then((response) => {
              console.log("-> accept/reject reponse: ", response);
            
              // this.updateProgressBar("deleted!");
              //this.message = response.data.message;
              this.message = "Contract delete all contract successfull";

              setTimeout(function () { // why timeout ?
                _this.message = "";
                _this.login()
                _this.resetVars()

                _this.showProgress = false;
              }, 1000);

            })
            .catch(() => {
              this.showProgress = false;
              // this.progress = 0;
              this.message = "Contract delete all failed";
              this.resetVars()
              var _this = this
              setTimeout(function () { // why timeout ?
                _this.showViewDataSection()
              }, 2000);

            });
        },
        setowner() {

          console.log("NEW OWNER IS",document.getElementById('newowner').value)
          var newowner = document.getElementById('newowner').value
          if (this.cubitsfs.utility.validate_wallet_address(newowner)) {
            this.newowner = this.cubitsfs.utility.validate_wallet_address(newowner)
          } else {
            this.newowner = this.NEWOWNER_ERRORNAME
          }
          
        },
        changeowner() { // add field for newowner
          // this.progress = 0;

          if (!this.contractCallback) {
            this.message = this.MESSAGE_INVALIDCONTRACTSELECTED;
            return;
          }

          var _this = this;
          this.showProgress = true;
          this.message = "";

          // var self = this;
          // self.updateProgressBar("Waiting for user confirmation2...");
          

          this.message = "Confirming owner change";
          //UploadService.remove(this.cubitsProtocolKey, this.contractCallback).then((response) => {
            this.cubitsfs.changeOwnership(this.newowner,this.contractCallback["_index"]).then((response) => {
              console.log("-> accept/reject reponse: ", response);
            
              // this.updateProgressBar("deleted!");
              //this.message = response.data.message;
              this.message = "Contract owner change successful";


              setTimeout(function () {
                  _this.message = "";
                  _this.login()

                  _this.resetVars()
                  _this.showProgress = false;
              }, 1000);

            })
            .catch(() => {
              this.showProgress = false;
              // this.progress = 0;
              this.message = "Contract owner change failed";
              this.resetVars()
            });
        },
      }
    };
    </script>
