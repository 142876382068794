<template>
  <footer class="footer footer-fullwidth">
      <div class="footer-info">
          <div class="row align-items-center justify-content-between pl-3 pr-1">
              <div class="col-md-4 text-left">
                  <p class="text-white mb-0">
                      Copyright © {{ new Date().getFullYear() }} Storageprotocol.
                  </p>
              </div>
              <div class="col-md-4 text-center mb-1">
                  <i class="fab fa-github mr-5 p-2 fa-lg text-white social-icon" @click="openSocials('github')"></i>
                  <i class="fab fa-discord mr-5 p-2 fa-lg text-white social-icon" @click="openSocials('discord')"></i>
                  <i class="fab fa-twitter mr-5 p-2 fa-lg text-white social-icon" @click="openSocials('twitter')"></i>
                  <i class="fab fa-instagram mr-5 p-2 fa-lg text-white social-icon" @click="openSocials('instagram')"></i>
                  <i class="fab fa-youtube mr-5 p-2 fa-lg text-white social-icon" @click="openSocials('youtube')"></i>
              </div>
              <div class="col-md-4 text-right">
                  <v-btn class="metamask_button main_color_header text-white" @click="AddMetamask()">Add Storage Protocol</v-btn>
              </div>
          </div>
      </div>
      <div class="tap-top">
          <div>
              <i class="fa fa-angle-double-up"></i>
          </div>
      </div>
  </footer>

</template>
  <script>
  export default {
    name: 'Footer',
    // data() {
    // return {
    //     addmm: this.AddMetamask,
    //   }
    // },
    methods: {
      AddMetamask() {

        if (window.ethereum) {
          window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
              chainId: "0x19105284",
              rpcUrls: ["https://rpc.storageprotocol.com/"],
              chainName: "StorageProtocol Mainnet",
              nativeCurrency: {
                name: "CUBITS",
                symbol: "CUBITS",
                decimals: 18
              },
              blockExplorerUrls: ["https://explorer.storageprotocol.com/"]
            }]
          });
        } else {
          // Handle the case where MetaMask or another Ethereum provider is not installed
          window.alert("Please install MetaMask or another StorageProtocol provider.");
        }

        return true
      },
      openSocials(name) {
        switch (name) {
          case 'github':
            window.open('https://github.com/StorageProtocol', '_blank');
            break;
          case 'discord':
            // You can replace 'your-discord-server' with your Discord server invite link
            window.open('https://discord.gg/7k5MFD2msP', '_blank');
            break;
          case 'twitter':
            window.open('https://twitter.com/StorageProtocoI', '_blank');
            break;
          case 'instagram':
            window.open('https://www.instagram.com/storageprotocol/', '_blank');
            break;
          case 'youtube':
            window.open('https://www.youtube.com/user/storageprotocol', '_blank');
            break;
          default:
            break;
        }
      }
    }

  };


  </script>
  
  <style scoped>
  .social-icon {
    cursor: pointer; /* Set cursor to pointer */
  }
 


  
  </style>
  