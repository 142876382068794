<template>
  <b-navbar type="light" class="navbar-light theme-nav fixed-top border-5 border-dark border-bottom" toggleable="lg" fixed="top" v-b-scrollspy:nav-scroller>
    <div class="container">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand class="navbar-brand" :to="{name:'Main_page'}"><img :src='"../assets/images/storageprotocol/storage_protool_logo.svg"' height="80" alt="logo"></b-navbar-brand>
      <b-collapse class="default-nav" is-nav id="nav_collapse">
        <b-navbar-nav class="navbar-nav ml-auto" id="mymenu">
          <b-nav-item style="cursor: pointer;" @click="openHome">Home</b-nav-item>
          <b-nav-item style="cursor: pointer;" @click="openNodes">Nodes</b-nav-item>
          <b-nav-item style="cursor: pointer;" @click="openBridge">Bridge</b-nav-item>
          <b-nav-item style="cursor: pointer;" @click="openExplorer1">Explorer</b-nav-item>
          <!-- <b-nav-item style="cursor: pointer;" @click="openApi">Api</b-nav-item> -->
          <b-nav-item style="cursor: pointer;" @click="openApi">Api</b-nav-item>
          <b-nav-item style="cursor: pointer;" @click="openStatistics">Stats</b-nav-item>
          <b-nav-item style="cursor: pointer;" @click="openInfo">Charts</b-nav-item>
          <!-- <b-nav-item style="cursor: pointer;" @click="openDocumentation">Whitepaper</b-nav-item> -->
  
          <!-- <b-nav-item href="#team">Team</b-nav-item> -->
  
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
  </template>
  <script>
  export default {
    name: 'nav-bar',
    methods: {
      openHome() {
        window.open('https://storageprotocol.com');
      },
      openApi() {
        // window.open('https://api.storageprotocol.com');
        window.open('https://api.storageprotocol.com');
      },
      openDocumentation() {
        window.open('https://docs.storageprotocol.com');
      },
      openInfo() {
        window.open('https://info.storageprotocol.com');
      },
      openStatistics() {
        window.open('https://stats.storageprotocol.com');
      },
      openBridge() {
        window.open('https://bridge.storageprotocol.com');
      },
      openUploads() {
        window.open('https://uploads.storageprotocol.com');
      },
      openNodes() {
        window.open('https://nodes.storageprotocol.com');
      },
      openExplorer1() {
        window.open('https://explorer.storageprotocol.com/');
      },
      openExplorer2() {
       
      },
    },
  };
  </script>