<template>
  <v-card elevation="0">

    <v-tabs v-model="tab" color="deep-blue" align-tabs="center">
      <v-tab v-for="(system, index) in Object.keys(systems)" :key="index" :value="index">
        {{ system }}
      </v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item v-for="(system, index) in Object.keys(systems)" :key="index" :value="index">
        <div v-if="tab === index">
          <!-- Content for other scripts in the current system -->
          <v-card v-for="(script, scriptName) in systems[system]" :key="scriptName" class="mx-auto mb-1">
            <!-- Content for the current system -->
            <div class="mx-auto mb-0" v-if="scriptName === 'Install Service'">
              <v-card-title>
                Set Wallet
              </v-card-title>
              <v-card-subtitle>
                <v-text-field
                  v-model="wallet_id"
                  :rules="[walletrules.required, walletrules.start, walletrules.min]"
                  label="Wallet ID"
                  @change="changeWallet"
                ></v-text-field>
                <v-chip class="ma-2 pa-2" color="success" v-if="setWalletSuccess" style="position: absolute; top: 96px; right: 4px;">
              
                  {{ walletStatus }}
                </v-chip>
              </v-card-subtitle>

            </div>

            <v-card-title>
              {{ getLabel(system, scriptName) }}
            </v-card-title>
            <v-card-subtitle>
              <copy-field
                :label="script.label"
                :text="createScript(script.scripttext, scriptName)"
                :rowsize="script.rowsize || script.scripttext.length || 4"
                :walletvalid="walletvalid || scriptName !== 'Install Service'"
                @copy="handleCopy"
                :elementId="`${system}-${scriptName}-textfield`"
              ></copy-field>
            </v-card-subtitle>
          </v-card>
        </div>
      </v-window-item>
    </v-window>
  </v-card>
</template>

 <script>


import CopyField from "./CopyField";

export default {
      name: "node-install",
      components: {
        // LinuxInstall
        CopyField
      },
      data() {
        return {
          // other data properties
          tab: null,
          setWalletSuccess:false,
          walletStatus: 'Wallet SET!', // Initial value
          nodeinstallscript_text: "",
          replication_slider: 10,
          date_create: new Date(new Date().getTime() + 60 * 60 * 24 * 1000).toISOString().split('T')[0],
          date_extend: "2018-03-20",
          install_script_text: "This will get copied!",
          wallet_id: "0x0000000000000000000000000000000000000000",
          walletrules: {
            required: value => (!!value && value != "0x0000000000000000000000000000000000000000") || "Required.",
            start: value => value.toLowerCase().startsWith("0x") || "Needs to start with 0x",
            min: v => (v && v.length == 42) || "Must match 42 characters"
          },
          walletvalid: false,
          dialog_getting_file: false,
          menu2: false,
          cubitsfs: window.cubitsfs,
          directoryUploadCost: 0,
          expandDays: 0,
          inputs_readonly: false,
          expandPrice: 0,
          message: "",
          systems: {
  "windows": {
    // "Update Machine": {
    //   "scripttext": [
    //     "apt-get update",
    //     "apt-get dist-upgrade -y",
    //     "mkdir /var/run/fail2ban",
    //     "apt-get install sudo ufw fail2ban nano -y"
    //   ],
    //   "label": "windows update machine"
    // },
    // "Create User": {
    //   "scripttext": [
    //     "adduser cubitsnode",
    //     "usermod -aG sudo cubitsnode",
    //     "adduser cubitsnode systemd-journal"
    //   ],
    //   "label": "windows create user"
    // },
    "Install Service": {
      "scripttext": [
        "mkdir \"C:/Temp/StorageProtocol\"",
        "cd /d \"C:/Temp/StorageProtocol\"",
        "del upgrade-win.bat",
        "powershell -command \"(New-Object Net.WebClient).DownloadFile('https://nodes.storageprotocol.com/download/upgrade-win.bat', 'upgrade-win.bat')\"",
        "upgrade-win.bat -w"
      ],
      "rowsize": 8,
      "label": "windows install service"
    }
  },
  "linux": {
    "Update Machine": {
      "scripttext": [
        "apt-get update",
        "apt-get dist-upgrade -y",
        "mkdir /var/run/fail2ban",
        "apt-get install sudo ufw fail2ban nano -y"
      ],
      "label": "windows update machine"
    },
    "Create User": {
      "scripttext": [
        "adduser cubitsnode",
        "usermod -aG sudo cubitsnode",
        "adduser cubitsnode systemd-journal"
      ],
      "label": "windows create user"
    },
    "Install Service": {
      "scripttext": [
        "mkdir -p /tmp/storageprotocol",
        "cd /tmp/storageprotocol",
        "rm -rf upgrade-debian.sh",
        "wget https://nodes.storageprotocol.com/download/upgrade-debian.sh",
        "chmod +x upgrade-debian.sh",
        "./upgrade-debian.sh -w"
      ],
      "rowsize": 8,
      "label": "windows install service"
    }
  },
  "mac": {
  
  },
  "arm": {
   
  }
}
        };
      },
      methods: {
        getLabel(system, scriptName) {
          // if (scriptName === 'Install Service') {
          //   return 'Set Wallet';
          // }
          return scriptName;
        },
        handleCopy(copiedText) {
          console.log("Copied Text:", copiedText);

        },
        getScript(system, scriptName) {
          return this.systems[system][scriptName].join('\n');
        },
        copyInstallText(system, scriptName) {
          const elementId = `${system}-${scriptName}-textfield`; // Adjust this based on your naming convention
          const element = document.getElementById(elementId);

          if (element) {
            element.select();
            element.setSelectionRange(0, 99999);
            document.execCommand('copy');

          }
        },
        changeWallet() {
          console.log("CHANGING WALLET")
          this.setWalletSuccess = true;
          setTimeout(() => {
            this.setWalletSuccess = false;
          }, 1300);


          // let previous_text = document.getElementById("copytextfield")
          // console.log("previous_text",previous_text)
          // if (previous_text && previous_text.value != this.nodeinstallscript_text) {
          //   this.copiedcodeworked = false;
          // }

          // walletrules.required, walletrules.start, walletrules.min
          // if (this.walletrules.required(this.wallet_id) == true && this.walletrules.start(this.wallet_id) == true && this.walletrules.min(this.wallet_id) == true) {
          //   console.log("CHANGE WALLET",this.walletrules.min(this.wallet_id))
          //   this.nodeinstallscript_text = this.installscript_basetext + "'" + this.wallet_id + "'\n"
          //   this.walletvalid = true;

          // } else {
          //   this.walletvalid = false;
          // } 
        },

        createScript(script, scriptName) {
          console.log("CREATE SCRIPT",scriptName)

          let _scripttext = script.join('\n')
          if (this.walletrules.required(this.wallet_id) == true && this.walletrules.start(this.wallet_id) == true && this.walletrules.min(this.wallet_id) == true) {
            console.log("CHANGE WALLET",this.walletrules.min(this.wallet_id))
            _scripttext = _scripttext.replace('.bat -w','.bat -w '+this.wallet_id).replace('.sh -w','.sh -w '+this.wallet_id)

            // if (this.walletvalid == false & this.setWalletSuccess == false) {
            //   this.setWalletSuccess = true;
            //   setTimeout(() => {
            //     this.setWalletSuccess = false;
            //   }, 900);
            // }
            this.walletStatus = "WALLET OK: "+ this.wallet_id;

            this.walletvalid = true;




          } else {
            // if (scriptName == "UserCreation") { // always allow copying of usercreation
            //   this.walletvalid = false;
            // } else {
            //   this.walletvalid = false;
            // }
            this.walletvalid = false;

            // this.walletStatus = "BAD WALLET: "+ this.wallet_id;
            this.setWalletSuccess = false
            
          } 


          return _scripttext
        },

  


      },
      mounted() {
        // window.ethereum.on('accountsChanged', async () => {
        //   console.log("SWITCHED ACCOUNTS",this)
        //   this.logout()
        //   this.login()
        // });
      }
    };
    </script>
