<template>
  <div :class="['container mt-5 mb-5 pb-1', !darkTheme ? 'dark-theme5' : 'bright-theme5']">

    <!-- Table for the newest version -->
    <div :class="['download-section mb-4', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
      <h2 :class="['mt-3', !darkTheme ? 'dark-theme2' : 'bright-theme2']">Current Version</h2>
      <table style="border-collapse: collapse; border: 1px solid rgb(81, 81, 81);">
        <thead>
          <tr>
            <th class="name-column">Name</th>
            <th>Checksum (Sha256)</th>
            <th>Version</th>
            <th class="date-column">Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="sortedFiles.length > 0">
            <td>{{ sortedFiles[0].name }}</td>
            <td>{{ sortedFiles[0].checksum }}</td>
            <td>{{ sortedFiles[0].version }}</td>
            <td>{{ formatDate(sortedFiles[0].date) }}</td>
            <td><button @click="downloadFile(sortedFiles[0].url,sortedFiles[0].downloadname)">Download</button></td>
          </tr>
          <tr v-else>
            <td colspan="5">No files available</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Table for older versions -->
    <div :class="['download-section mb-0', !darkTheme ? 'dark-theme5' : 'bright-theme5']">
      <h2 :class="['', !darkTheme ? 'dark-theme2' : 'bright-theme2']">Older Versions</h2>
      <table style="border-collapse: collapse; border: 1px solid rgb(81, 81, 81);">
        <thead>
          <tr>
            <th class="name-column">Name</th>
            <th>Checksum (Sha256)</th>
            <th>Version</th>
            <th class="date-column">Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="file in sortedFiles.slice(1)" :key="file.name"> -->
          <tr v-for="file in sortedFiles" :key="file.checksum">
            <td>{{ file.name }}</td>
            <td>{{ file.checksum }}</td>
            <td>{{ file.version }}</td>
            <td>{{ formatDate(file.date) }}</td>
            <td><button @click="downloadFile(file.url, file.downloadname)">Download</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div :class="['mt-5', !darkTheme ? 'dark-theme2' : 'bright-theme2']"></div>
  </div>
</template>

<script>
export default {
  props: {
    darkTheme: {
      type: Boolean,
      required: false
    },
    files: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedFiles() {
      return this.files.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },
  methods: {
    async downloadFile(url, filename) {
      const response = await fetch(url);
      const blob = await response.blob();

      const anchor = document.createElement('a');
      anchor.style.display = 'none';

      const blobUrl = window.URL.createObjectURL(blob);

      anchor.href = blobUrl;
      anchor.download = filename;

      document.body.appendChild(anchor);

      anchor.click();

      document.body.removeChild(anchor);

      window.URL.revokeObjectURL(blobUrl);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }
  }
};
</script>

<style scoped>
.name-column {
  width: 50px;
}

.date-column {
  width: 120px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.download-section {
  margin: 20px auto;
  /* max-width: 800px; */
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  text-align: left;
  border-top:1px rgb(155, 155, 155) solid;
}
td {
  background-color:rgb(244, 244, 244);
}


thead {
  background-color: #f2f2f2;
}

th {
  font-weight: bold;
}

button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}
</style>
