<template>
    <v-card class="mx-auto" style="background: rgba(255, 255, 255, 0.75);margin-bottom:-160px;">
      <!-- <v-card-title style="height:20px">
        Network Activity
      </v-card-title> -->
  
      <v-divider></v-divider>
  
      <v-list height="120" class="virtual-scroll-list" style="background: rgba(255, 255, 255, 0.75);">
        <v-list-item
          v-for="item in items"
          :key="item.id"
          class="employee-list-item"
          style="height:10px;min-height:16px"
        >
          <v-list-item-content>
            <v-list-item-title class="employee-title">
              {{ item.name }}
            </v-list-item-title>
            <!-- <v-list-item-subtitle class="employee-subtitle">
              Badge #{{ item.blockNr }}
            </v-list-item-subtitle> -->
          </v-list-item-content>
  
          <v-list-item-action>
            <v-icon :class="item.iconClass">{{ item.icon }}</v-icon>
          </v-list-item-action>
  
          <v-list-item-action>
            <v-btn
              icon
              size="x-small"
              variant="tonal"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </template>
  
  <script>
  export default {
    data: () => ({
      items: [],
    }),
    mounted() {
    //   window.cubitsfs.on('NewBlock', (newblock) => {
    //     console.log("newblock", newblock);
  
    //     const customIcon = 'mdi-account'; // Replace with the appropriate icon class
    //     const customText = `Block #${newblock.blockNumber}`; // Use the current block number
  
    //     // Function to add items with a delay
    //     const addItemDelayed = () => {
    //       this.items.push({
    //         id: newblock.id,
    //         name: "NEW" + customText,
    //         blockNr: newblock.blockNumber,
    //         icon: customIcon,
    //         iconClass: 'employee-icon', // Add your custom styling class
    //       });
  
    //       // Check if 10 items have been added
    //       if (this.items.length < 10) {
    //         // Set timeout for the next iteration after 3 seconds
    //         setTimeout(addItemDelayed, 3000);
    //       }
    //     };
  
    //     // Call the function to start the process
    //     addItemDelayed();
    //   });
      window.cubitsfs.on('OnCreateContract', (contractData) => {
        window.cubitsfs.get_contract_by_id(contractData.contractId).then((contract) => {
                let customIcon = 'mdi-account'; // Replace with the appropriate icon class
                // let customText = `Block #${contractData.blockNumber}`; // Use the current block number
    
                console.log("contract created:",contract);
                    this.items.push({
                    id: contractData.contractId,
                    name: "NEW CONTRACT: " + contract.ownerAddress + " " + contract.hostingContractName,
                    blockNr: contract.deployedDaysLength,
                    icon: customIcon,
                    iconClass: 'employee-icon', // Add your custom styling class
                });

            })

        });

    },
  };
  </script>
  
  
  <style scoped>
  .virtual-scroll-list {
    overflow-y: auto;
  }
  
  .employee-list-item {
    border-bottom: 1px solid #e0e0e0;
  }
  
  .employee-title {
    font-weight: bold;
  }
  
  .employee-icon {
    color: black !important; /* Set icon color to black */
  }
  
  .employee-subtitle {
    color: black !important; /* Set subtitle color to black */
  }
  </style>
  