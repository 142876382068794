<template>
    <v-timeline align="start">
      <v-timeline-item
        v-for="(timelineEntry, i) in timelineEntrys"
        :key="i"
        :dot-color="timelineEntry.color_dot"
        size="small"
      >
        <template v-slot:opposite>
          <div
            :class="`pt-1 headline font-weight-bold text-${timelineEntry.color}`"
            v-text="timelineEntry.value"
          ></div>
        </template>
        <div>
          <h2 :class="`mt-n1 headline font-weight-light mb-4 text-${timelineEntry.color_title}`">
            {{timelineEntry.title}}
          </h2>
          <div :class="`text-${timelineEntry.color_description}`">
            {{timelineEntry.description}}
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </template>

<script>
export default {
  data: () => ({
    timelineEntrys: [],
    timeLineData: [
    {
        title: "Network Size",
        description: "Network Size description",
        color_dot: "blue",
        color_title: "blue",
        color_description: "blue",
        async getValue() {
          return await window.cubitsfs.api.data.storage.getUsableStorageSize(true);
        },
      },
      {
        title: "Node Count",
        description: "Node Count description",
        color_dot: "blue",
        color_title: "blue",
        color_description: "blue",
        async getValue() {
          return await window.cubitsfs.api.data.nodes.get_node_count();
        },
      },
      {
        title: "Node Collateral",
        description: "Node Collateral description",
        color_dot: "blue",
        color_title: "blue",
        color_description: "blue",
        async getValue() {
          return await window.cubitsfs.api.data.nodes.get_node_collateral(true);
        },
      },
      {
        title: "Node Share",
        description: "Node Share description",
        color_dot: "blue",
        color_title: "blue",
        color_description: "blue",
        async getValue() {
          return await window.cubitsfs.api.data.nodes.getCubitShare(true,3);
        },
      },
    ],
  }),
  async mounted() {
    for (let item of this.timeLineData) {
      let value = await item.getValue();
      this.timelineEntrys.push({
        title: item.title,
        description: item.description,
        color_dot: item.color_dot,
        color_title: item.color_title,
        color_description: item.color_description,
        value:value,
      });
    }
  },
};
</script>
  