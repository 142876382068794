<template>
  <div>
    <div v-for="(apiList, category) in categorizedApiLists" :key="category">
      <v-divider v-if="category !== 'NETWORK'"></v-divider>
      <!-- <v-divider></v-divider> -->

      <v-data-table v-if="apiList.length > 0" :headers="headers" :items="apiList" hide-default-footer>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>EVENTS</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-toolbar-title>{{ category.toUpperCase() }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-toolbar-title>WEB3</v-toolbar-title> -->
          </v-toolbar>
        </template>
          <!-- <template v-slot:item="{ item }">
          <tr @click="closeResponse(item)">
            <td >{{ getFuncName(item) }}<b>{{ formatInputArguments(item) }}</b></td>
            <td>
              <span v-html="formatDescription(item.description)"></span>
            </td>
          </tr>
          <tr v-if="item.showResponse">
            <td :colspan="headers.length+2">
              <v-text-field v-model="item.outputText" label="Output" readonly></v-text-field>
            </td>
          </tr>
        </template> -->
      </v-data-table>
      <v-divider></v-divider>

    </div>

   
      <v-row>
        <v-col>
          <v-card>
            <!-- <v-card-title>Event Showcase: OnCreateContract</v-card-title> -->
            <!-- <v-card-title>Event Showcase:<v-select v-model="selectedEvent" :items="eventOptions" label="Select Event"></v-select></v-card-title> -->
            <!-- <v-card-title>Event Showcase:
              <v-select v-model="selectedEvent" :items="eventOptions" label="Select Event" class="ml-2"></v-select>
            </v-card-title> -->
            <v-card-title>Event Showcase:
              <v-select v-model="selectedEvent" :items="eventOptions" label="Select Event" class="ml-2" style="max-width: 150px;" @click="handleSelectClick"></v-select>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col> 
                  <!-- <v-textarea v-model="codeSnippet" label="Code Snippet" readonly></v-textarea> -->
                  <v-textarea
                    v-model="codeSnippet"
                    label="Code Snippet"
                    readonly
                    outlined
                    dense
                    hide-details
                    auto-grow
                    rows="4"
                    style="font-family: 'Roboto', sans-serif; font-size: 14px; color: #333; background-color: #f5f5f5; padding: 10px; border-radius: 4px;"
                  ></v-textarea>

                  <v-btn class="mt-4" @click="copyToClipboard" color="primary">Copy to Clipboard</v-btn>
                </v-col>

                <v-col> 
                  <!-- <v-textarea v-model="codeSnippet" label="Code Snippet" readonly></v-textarea> -->
                  <v-textarea
                    v-model="liveHookResult"
                    label="Live Hook Result"
                    readonly
                    outlined
                    dense
                    hide-details
                    auto-grow
                    rows="5"
                    style="font-family: monospace; white-space: pre-wrap;"
                  ></v-textarea>

                  <!-- <v-btn @click="copyToClipboard" color="primary">Copy to Clipboard</v-btn> -->
                </v-col>


              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
  </div>




</template>

<script>
export default {
  data() {
    return {
      liveHookResult: '',
      selectedEvent: 'NewBlock',
      eventOptions: [],
      headers: [
        { text: 'Function Name', value: 'funcName' },
        { text: 'Description', value: 'description',sortable:false },
      ],
      categorizedApiLists: {
        NETWORK: [
          {
            funcName: 'NewBlock',
            description: 'NewBlock event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewTransaction',
            description: 'NewTransaction event detected externally.',
            showResponse: false,
            outputText: '',
          },
        ],
        USDT: [
          {
            funcName: 'Mint',
            description: 'New Mint event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'Burn',
            description: 'New Burn event detected externally.',
            showResponse: false,
            outputText: '',
          },
        ],
        CUBITS: [
          {
            funcName: 'BuyCubits',
            description: 'New BuyCubits event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'SellCubits',
            description: 'New SellCubits event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'CreateNodeRewardCubits',
            description: 'New CreateNodeRewardCubits event detected externally.',
            showResponse: false,
            outputText: '',
          },
        ],
        NODES: [
          {
            funcName: 'NodeReward',
            description: 'New NodeReward event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NodeRewardStack',
            description: 'New NodeRewardStack event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'DailyPayoutTrigger',
            description: 'New DailyPayoutTrigger event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewPayoutTreshold',
            description: 'New NewPayoutTreshold event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewPayoutCount',
            description: 'New NewPayoutCount event detected externally.',
            showResponse: false,
            outputText: '',
          },
        ],
        MULTISIG: [
          {
            funcName: 'NewBridgeReceipt',
            description: 'New Bridge Receipt event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewTransactionExecution',
            description: 'New NewTransactionExecution event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'TransactionFailure',
            description: 'New TransactionFailure event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'NewSignature',
            description: 'New NewSignature event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'BadSignature',
            description: 'New BadSignature event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'SignatureOverwrite',
            description: 'New SignatureOverwrite event detected externally.',
            showResponse: false,
            outputText: '',
          },
        ],
        STORAGE: [
          {
            funcName: 'OnNewContractHost',
            description: 'New OnNewContractHost event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnCreateContract',
            description: 'New OnCreateContract event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnRemoveContract',
            description: 'New OnRemoveContract event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnExtendContract',
            description: 'New OnExtendContract event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnContractExpired',
            description: 'New OnContractExpired event detected externally.',
            showResponse: false,
            outputText: '',
          },
          {
            funcName: 'OnChangedOwnership',
            description: 'New OnChangedOwnership event detected externally.',
            showResponse: false,
            outputText: '',
          },
        ],
      },
    };
  },
  computed: {
    categorizedEvents() {
      const events = [];
      for (const category in this.categorizedApiLists) {
        if (Object.prototype.hasOwnProperty.call(this.categorizedApiLists, category)) {
          this.categorizedApiLists[category].forEach((event) => {
            events.push(event.funcName);
          });
        }
      }
      return events;
    },
    codeSnippet() {
      if (!this.selectedEvent) return ''; // Return empty string if no event is selected

      // Replace "oncreatecontract" with the selected event name
      return `window.cubitsfs.on('${this.selectedEvent}', (data) => {
        console.log('----> New ${this.selectedEvent} event detected externally. Data:', data);
        // Your code to handle the ${this.selectedEvent} event
});`;
    }
  },
  methods: {
    handleSelectClick() {
        // Your logic for handling the click event
        console.log("Select clicked!");
        this.liveHookResult = "";
    },
    populateEventOptions() {
      this.eventOptions = this.categorizedEvents;
    },
    copyToClipboard() {
      // Copy code snippet to clipboard
      navigator.clipboard.writeText(this.codeSnippet);
    },
    formatCurrentDateTime() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = currentDate.getFullYear().toString().substr(2,4);
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      return `${hours}:${minutes}:${seconds} @ ${day}.${month}.${year}`;
    },
    serializeObjectToString(obj) {
        let result = '';
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[key] === 'object') {
                    result += `${key}:${JSON.stringify(obj[key])} - ${this.formatCurrentDateTime()}\n`;
                } else {
                    result += `${key}:${obj[key]} - ${this.formatCurrentDateTime()}\n`;
                }
            }
        }
        return result.trim(); // Trim any trailing newline
    },
    liveHook(data) {
      // Simulate live hook result (replace this with actual logic)
      console.log("aaaaaaaaaaaaadata",data)
      this.liveHookResult += this.serializeObjectToString(data) +"\n";
    },
    getFuncName(item) {
      return item.funcName;
    },
    formatInputArguments() {
      // Implement your logic to format input arguments
      return ''; // Placeholder, update as needed
    },
    formatDescription(description) {
      // Implement your logic to format the description
      return description;
    },
    closeResponse(item) {
      // Implement your logic to close the response
      item.showResponse = !item.showResponse;
    },
  },
  mounted() {
    // Attach live hook to the specified event
    // window.cubitsfs.on('NewBlock', (data) => {
    //   // Execute live hook logic here
    //   console.log("---------------------------------data",data)
    //   this.liveHook(data);
    // });
    for (const category in this.categorizedApiLists) {
      if (Object.prototype.hasOwnProperty.call(this.categorizedApiLists, category)) {
        this.categorizedApiLists[category].forEach((event) => {
          window.cubitsfs.on(event.funcName, (data) => {
            if (event.funcName === this.selectedEvent) {
              // Execute live hook logic here
              console.log("---------------------------------data", data);
              this.liveHook(data);
            }
          });
        });
      }
    }

    this.populateEventOptions();
  },
};
</script>
