

<template>

  <v-app>
    
    <!-- Preloader -->
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
    <!-- Preloader end -->

    
    <div style="margin-top:120px;display:none">
      <ConsoleWindow></ConsoleWindow>
    </div>
    <div style="margin-top:140px;display:none">
      <NetworkTimeline></NetworkTimeline>
    </div>
    <div style="margin-top:140px;display:none">
      <Api2></Api2>
    </div>
    <div style="margin-top:140px;display:none">
      <Api3></Api3>
    </div>
    <!-- <div style="margin-top:140px;display:none">
      <StorageCharts></StorageCharts>
    </div> -->

<!-- DOCUMENTATION SDK -->
<div>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_documentation"
      width="800"
      style="z-index:99999"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">What is Storage Protocol?</span>
        </v-card-title>
        <v-card-text>
          Storage Protocol is a blockchain.
        </v-card-text>


        <v-card-title>
          <span class="text-h5">Web3 Hooks</span>
          <Api3></Api3>
        </v-card-title>
        <v-card-text>
          The Storage Protocol SDK can hook onchain functions. 
        </v-card-text>


        <v-card-title>
          <span class="text-h5">Web3 Calls</span>
        </v-card-title>
        <v-card-text>
          Storage Protocol offers a variety of onchain computations. 
          Below is a list of permanently available functions to be called via the SDK.
        </v-card-text>

        <v-card-text>

          <Api2></Api2>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialog_documentation = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialog_documentation = false"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</div>
<!-- DOCUMENTATION SDK END -->

 

<!-- WEB HOOKS -->
<div>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_web3hooks"
      width="800"
      style="z-index:99999"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">What is Storage Protocol?</span>
        </v-card-title>
        <v-card-text>
          Storage Protocol is a blockchain.
        </v-card-text>


        <v-card-title>
          <span class="text-h5">Web3 Hooks</span>
          <Api3></Api3>
        </v-card-title>

      </v-card>
    </v-dialog>
  </v-row>
</div>
<!-- WEB HOOKS  END -->

<!-- WEB HOOKS -->
<div>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_api"
      width="800"
      style="z-index:99999"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5">What is Storage Protocol?</span>
        </v-card-title>
        <v-card-text>
          Storage Protocol is a blockchain.
        </v-card-text>


        <v-card-title>
          <span class="text-h5">Web3 Hooks</span>
          <Api2></Api2>
        </v-card-title>

      </v-card>
    </v-dialog>
  </v-row>
</div>
<!-- WEB HOOKS  END -->


<!-- DOWNLOAD SDK -->
    <div>
    <v-row
      justify="center"
    >

    <v-dialog
        v-model="dialog_sdk"
        width="auto"
      >
        <v-card>
          <DownloadPage :files="sdk_files"></DownloadPage>
        </v-card>


      </v-dialog>

    
    </v-row>
  </div>
<!-- DOWNLOAD SDK END -->

<!-- DOWNLOAD GETH -->
<div>
    <v-row
      justify="center"
    >

    <v-dialog
        v-model="dialog_geth"
        width="auto"
      >
        <v-card>
          <DownloadPage :files="geth_files"></DownloadPage>
        </v-card>


      </v-dialog>

    
    </v-row>
  </div>
<!-- DOWNLOAD GETH END -->



    <Header></Header>
    
    <!-- <vue-progress-bar></vue-progress-bar> -->
  <div id="main_div" class="main_div main-background" :style="{ backgroundAttachment: 'fixed', backgroundSize :'2600px', backgroundPositionY :'20px', backgroundPositionX :'center', backgroundRepeat :'repeat', backgroundImage: `url(${require('@/assets/images/storageprotocol/bg4k.png')})` }">
    <!-- <div id="main_div" class="main_div main-background" :style="{ backgroundAttachment: 'fixed', backgroundSize :'50%', backgroundPositionY :'50%', backgroundPositionX :'center', backgroundRepeat :'repeat-x', backgroundImage: `url(${require('@/assets/images/storageprotocol/bg5a2.jpg')})` }"> -->

    <v-container>


        <div class="h-full p-2">

        
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="home-contain" style="height: calc(100vh - 74px);">
              <div class="w-full p-2">
                <h4>#Web3</h4>
                <h1 style="font-size: 40px;" class="wrap">
                  <!-- <span class="f-bold f-color">Blockchain</span> -->

                  <!-- <div class="d-md-none f-bold pt-2 pb-2"> Storage </div> -->
                  <span class="f-bold f-color">Blockchain</span>
                  <span class="d-none d-md-inline f-bold"> Storage </span>
                </h1>



                <div class="container px-2 pt-8 flex flex-wrap flex-col md:flex-column" >

                  <h4 class="text-3xl font-bold leading-tight text-center w-50">
                    Download SDK
                    <!-- <b-button variant="outline-primary">Quick Start</b-button> -->
                    <div class="container px-2 pt-2 flex flex-wrap flex-col md:flex-column" >
                
                        <b-button class="mx-2 mb-1 second_header_color" @click="dialog_sdk = true">Download SDK</b-button>

                       <!-- <b-button class="mx-2 mb-1 second_header_color" @click="dialog_documentation = true" variant="outline-primary">Download GETH</b-button> -->
                       <!-- <b-button class="mx-2 mb-1 second_header_color" @click="downloadGethclient = true" variant="outline-primary">Download GETH</b-button> -->
                    </div>
                  </h4>

                  <h4 class="text-3xl font-bold leading-tight text-center w-50">
                    Download GETH
                    <!-- <b-button variant="outline-primary">Quick Start</b-button> -->
                    <div class="container px-2 pt-2 flex flex-wrap flex-col md:flex-column" >
                      <b-button class="mx-2 mb-1 second_header_color" @click="dialog_geth = true" variant="outline-primary">Download GETH</b-button>
                    </div>
                  </h4>

                  <!-- <h4 class="text-3xl font-bold leading-tight text-center w-50">
                    DOCUMENTATION
       
                    <div class="container px-2 pt-2 flex flex-wrap flex-col md:flex-column" >
      
                       <b-button class="mx-2 mb-1 second_header_color" @click="dialog_web3hooks = true" variant="outline-primary">WEB3 API </b-button>
                       <b-button class="mx-2 mb-1 second_header_color" @click="dialog_api = true" variant="outline-primary">SDK API </b-button>
                    </div>
                  </h4>                -->

                </div>



                <div class="container px-2 pt-8 flex flex-wrap flex-col md:flex-column" >
                  <h4 class="text-3xl font-bold leading-tight text-center w-50">
                    <!-- Assets -->
                    Assets
                    <!-- <b-button variant="outline-primary">Quick Start</b-button> -->
                    <div class="container px-2 pt-2 flex-col md:flex-column" >
                      <b-button class="mx-2 mb-1 second_header_color" @click="downloadWhitepaper">Whitepaper</b-button>

                      <!-- <b-button class="mx-2 mb-1 second_header_color" variant="outline-primary">Roadmap</b-button> -->
                      <!-- <div>
                        <b-button @click="downloadWhitepaper">Whitepaper</b-button>
                        <iframe ref="pdfViewer" style="width: 100%; height: 500px; border: none;"></iframe>
                      </div> -->
                    </div>
                  </h4>
                </div>


                


              </div>
            </div>
          </div>
          <div class="col-1 d-none d-md-block" />
          <div class="col-12 col-md-5 d-md-block">
            <div class="home-contain p-2">
              <div>
                <!-- Tile js start -->
                <div class="d-none d-md-block justify-content-center">
                  <!-- <img :src="require('@/assets/images/storageprotocol/cube_logo.svg')" style="width: 50%; object-fit: contain; filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)" /> -->
                  <!-- <img :src="require('@/assets/images/storageprotocol/cube_logo.svg')" style="width: 50%; object-fit: contain; filter: invert(0.81)" /> -->
                  
                  <img :src="require('@/assets/images/storageprotocol/cube_logo.svg')" class="blackcubits"  style="width: 221px; margin-top:3px; left:21px; z-index:; position:absolute;" />
                  <img :src="require('@/assets/images/storageprotocol/cube_logo.svg')" class="bluecubits" style="width: 220px; object-fit: contain;z-index:2" />
                  
                  <!-- <img :src="require('@/assets/images/storageprotocol/cube_logo2.png')" /> -->
                  <!-- <img :src="require('@/assets/images/storageprotocol/cube_logo.svg')" style="position:absolute;width: 50%; margin-top:10px; margin-left:10px; object-fit: contain; filter: invert(0.81)" />
                  <img :src="require('@/assets/images/storageprotocol/cube_logo.svg')" style="position:absolute;width: calc(50% + 20px); object-fit: contain; " /> -->
                  
                </div>
                <!-- Tile js end -->
                <h1 style="font-size: 24px;">
                  <span class="f-bold f-color " style="color:black">Decentralized Storage Network</span>
                </h1>
                  <v-btn
                    class="main_header_color"
           
                    dark
                    @click="toggleUploadApplication"
                  >
                    Upload
                  </v-btn>
                  <v-btn
                    class="main_header_color3 ml-2"
                    @click="toggleAddNodeApplication"
                  >
                    Add Node
                  </v-btn>
    
                <!-- <h6>Decentralized Data Upload <p style="background-color:white;border:solid 1px black">#Decryption Protected</p></h6> -->
                <h6>Decentralized Data Upload <p class="second_header_color pl-2">#Privacy #Encryption #Blockchain</p></h6>
                <!-- <h6>Decentralized Data Upload <p class="second_header_color" style="border:solid 1px black">#Decryption Protected</p></h6> -->
                <!-- <h6>Decentralized Data Upload Application <p class="second_header_color">#Decryption Protected</p></h6> -->
                <!-- <h6>Decentralized Data Upload Application<p><a href="https://ethoprotocol.com" style="color:#971B45;">#Decryption Protected</a></p></h6> -->

              </div>
              
            
      
  
            </div>
          </div>
        </div>
      </div>
      

      <v-row justify="center">
        <v-dialog
          v-model="uploadApplication"
   
          persistent
          max-width="600"
          style="z-index:99999"
        >

          <v-card max-height="80vh"> 
          <!-- <v-card>  -->
            <v-card-title class="headline">

              <v-toolbar-title>Select A Provider</v-toolbar-title>

            </v-card-title>
            <!-- <v-card-text style="background-color: white"> <upload-files></upload-files> </v-card-text> -->
             <upload-files></upload-files>
            <v-card-actions style="background-color: white" class="pt-0">
              <v-spacer></v-spacer>


              <v-btn
                color="blue darken-1"
                text
                @click="uploadApplication = false"
              >
                Close
              </v-btn>



            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>





      <v-row justify="center">
        <v-dialog
          v-model="addnodeApplication"
   
          persistent
          max-width="600"
          style="z-index:99999"
        >

          <v-card max-height="80vh"> 
          <!-- <v-card>  -->
            <v-card-title class="headline">

              <v-toolbar-title>Cubits Node - Installation</v-toolbar-title>

            </v-card-title>
            
             <node-install></node-install>
                <v-card-actions style="background-color: white" class="pt-0">
              <v-spacer></v-spacer>


              <v-btn
                color="#2479d8"
                text
                @click="addnodeApplication = false"
              >
                Close
              </v-btn>



            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>




<!-- 
      <v-container class="bg-surface-variant" style="margin-top:-240px">
      <v-row
        no-gutters
        
      >

        <v-col align-self="end">
        
            
         
        </v-col>
      </v-row>
      
    </v-container> -->


  </v-container>


  <!-- <Map></Map> -->
  <Footer></Footer>

  <js></js>
  </div>
  </v-app>
  </template>
  
  <script>
  
  import UploadFiles from "./components/UploadFiles";
  import NodeInstall from "./components/NodeInstall";
  import ConsoleWindow from "./components/ConsoleWindow"; // Update the path accordingly
  import NetworkTimeline from "./components/NetworkTimeline"; // Update the path accordingly
  import Api2 from "./components/Api2"; // Update the path accordingly
  import Api3 from "./components/Api3"; // Update the path accordingly

  import DownloadPage from './components/DownloadPage.vue';

  // import StorageCharts from "./components/StorageCharts"; // Update the path accordingly

  // import Home from "./components/Home";
  // import Map from "./components/map";
  import Footer from "./components/footer";
  import Header from "./components/header";
  import js from '@/components/js';

  // import { loadFull } from "tsparticles";
  
  export default {
    name: "App",
    components: {
    DownloadPage,
    UploadFiles,
    NodeInstall,
    ConsoleWindow,
    NetworkTimeline,
    Api2,
    Api3,
    // StorageCharts,
    // Home,
    Header,
    Footer,
    // Map,
    js
},
    // async particlesLoaded(container) {
    //   console.log("Particles container loaded", container);
    // },
    // async particlesInit(engine) {
    //   console.log(await loadFull(engine));
    // },
    data() {
      return {
        uploadApplication: false,
        addnodeApplication: false,
        dialog_sdk: false,
        dialog_geth: false,
        dialog_documentation: false,
        dialog_web3hooks: false,
        dialog_api: false,
        sdk_files: [
          {
            name: 'Cubits SDK',
            downloadname: 'cubits.js',
            checksum: 'a241e042b88a0bda3fef9bc81ec009f63073625fc38a08d5948a81fc81644c55',
            version: '0.0.2',
            date: '2024-02-12',
            url: 'https://raw.githubusercontent.com/StorageProtocol/cubitsSDK/main/cubits_sdk.js'
          },
          {
            name: 'Cubits SDK',
            downloadname: 'cubits.js',
            checksum: 'a9eb1aa63c98e31b2064c23e04ed28aaa426536c350ff33a62fe9b09ef274c12',
            version: '0.0.1',
            date: '2024-02-11',
            url: 'https://raw.githubusercontent.com/StorageProtocol/cubitsSDK/main/cubits_sdk.js'
          },
          // Add more files here
        ],
        geth_files: [
          {
            name: 'Cubits Geth',
            downloadname: 'geth',
            checksum: 'e2c4837e63917dc255a4c2c65ef84687e43a18b9666222f7a1c74342c0e4b733',
            version: '0.0.2',
            date: '2024-02-12',
            url: 'https://nodes.storageprotocol.com/download/cubits_v0_0_2.tar.gz'
          },
          {
            name: 'Cubits Geth',
            downloadname: 'geth',
            checksum: 'cdb0377491b35a37c572933b7636bd8e50016d7d4863dea38bed98e3d3188bc4',
            version: '0.0.1',
            date: '2024-02-11',
            url: 'https://nodes.storageprotocol.com/download/cubits_v0_0_1.tar.gz'
          },
          // Add more files here
        ],
      };
    },
    methods: {
      async downloadWhitepaper() {
        // const pdfURL = "https://raw.githubusercontent.com/StorageProtocol/Whitepaper/06f7f119cde83d33326594ab76205a82d430ed4a/whitepaper.pdf"
        const pdfUrl = 'https://raw.githubusercontent.com/StorageProtocol/Whitepaper/06f7f119cde83d33326594ab76205a82d430ed4a/whitepaper.pdf';
        try {
          // Make an HTTP GET request to download the PDF file
          const response = await fetch(pdfUrl);
          if (!response.ok) {
            throw new Error('Failed to download PDF: ' + response.statusText);
          }

          // Convert the response data to a blob with the PDF content type
          const blob = await response.blob();
          const blobData = new Blob([blob], { type: 'application/pdf'});
          // const blobData = new Blob([blob], { type: 'application/pdf', name: 'whitepaper.pdf'});
      
          console.log("blob",blob)
          console.log("blobData",blobData)
          // Create a URL for the blob object
          const blobURL = window.URL.createObjectURL(blobData);
          // const uuid = blobURL.split('/').pop();

          // Create a new blob URL with the filename set to "whitepaper.pdf"
          // const newBlobURL = blobURL.replace(uuid, 'whitepaper.pdf');

          // Open the PDF in a new tab. The PDF will be displayed inline due to the 'application/pdf' MIME type.
          // const newTab = window.open(blobURL, '_blank');
          const newTab = window.open(blobURL, '_blank');
          if (newTab) {
            newTab.onload = function() {
              setTimeout(() => {
                  newTab.document.title = 'whitepaper.pdf';
              }, 1); // Adjust the delay as needed (in milliseconds)
            };
          } else {
              console.error('Failed to open new tab.');
          }
          if (!newTab) {
            throw new Error('Failed to open PDF in new tab.');
          }
        } catch (error) {
          console.error('Error opening PDF:', error);
        }
      },
      async downloadGethclient() {
        const githubUrl = 'https://github.com/StorageProtocol/geth';
        try {
          const response = await fetch(githubUrl);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'gethclient');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading Geth client:', error);
        }
      },
      // async downloadWhitepaper() {
      //   const whitepaperUrl = "https://github.com/StorageProtocol/Whitepaper/blob/main/whitepaper.pdf";
      //   try {
      //     const response = await fetch(whitepaperUrl);
      //     const blob = await response.blob();
      //     const url = window.URL.createObjectURL(blob);
      //     const a = document.createElement("a");
      //     a.href = url;
      //     a.download = "whitepaper.pdf";
      //     document.body.appendChild(a);
      //     a.click();
      //     window.URL.revokeObjectURL(url);
      //   } catch (error) {
      //     console.error("Failed to download whitepaper:", error);
      //   }
      // },
      toggleUploadApplication() {
        
        if (this.uploadApplication == true) {
          console.log("HIDE UPLOAD")
          this.uploadApplication = false;
        } else {
          console.log("SHOW UPLOAD")
          this.uploadApplication = true;
        }
        
      },
      toggleAddNodeApplication() {
        
        if (this.addnodeApplication == true) {
          console.log("HIDE ADDNODE")
          this.addnodeApplication = false;
        } else {
          console.log("SHOW ADDNODE")
          this.addnodeApplication = true;
        }
        
        
      },
      hideUploadApplication() {
        this.uploadApplication = false;
      },
      hideAddNodeApplication() {
        this.addnodeApplication = false;
      },

      // removeAll() {
      //   UploadFiles.removeAll()
      //   console.log("removeAllremoveAllremoveAllremoveAll")
      // }
    },
    mounted() {
      // window.cubitsfs.on('onNewContract', (contractData) => {
      //     console.log('New contract detected externally. data:', contractData);
      //     console.log('Transaction hash:', contractData.transactionHash);
      //   window.cubitsfs.get_contract_by_id(contractData.contractId).then((result) => {
      //     console.log("contract:",result);
      //   })

      //     // Your code to handle the new contract externally
      // });

      window.cubitsfs.on('NewBlock', (newblock) => {
        console.log("newblock",newblock)
        // add newblock to vlist
      });



    }
  };
  </script>
